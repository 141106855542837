import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table,
    FormGroup, Label, Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from "react-loader-spinner";

//i18n
import { withNamespaces } from 'react-i18next';
import firebase from '../../firebase';
import axios from 'axios';
import { API_BASE_URL, API_KEY, renewAccessToken, setCookie, getCookie, sessionExpired } from "../../constants/utilis";
import { Bar, Line, Pie } from 'react-chartjs-2';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

function timeDifference(date1,date2) {

    var diff =(date1.getTime() - date2.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));

}

function reFormatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month].join('-');
}

function currentWeekDates(current) {
    var week= new Array(); 
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay() +1));
    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(current)
        ); 
        current.setDate(current.getDate() +1);
    }
    return week; 
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.funcMap = {
            '1': this.fetchList,
        };
        this.state = {
            reports: [
                // { title: "Orders", iconClass: "bx-cart-alt", description: "0" },
                { title: "Revenue", iconClass: "bx-archive-in" },
                { title: "Users", iconClass: "bx-user" }
            ],
            email: [
                { title: "Week", linkto: "#", isActive: false },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: true }
            ],
            modal: false,
            users: '',
            revenue: '0',
            paidUsers: '',
            normalUsers: '',

            unAssignedCases: '0',
            assignedCases: '0',
            verifiedCases: '0',
            totalCases: '0',
            hpCases: '0',
            pendingHPCases: '0',
            verfiedHPCases: '0',
            followUp: '0',
            tatReport: '0',
            activeExecutives: '0',
            inActiveExecutives: '0',
            notifications: [],
            startDate: '',
            endDate: '',
            year: '',
            commonStartDate: '',
            commonEndDate: '',
            banksCaseWise: [],
            statesCaseWise: [],
            analyticsStartDate: '',
            analyticsEndDate: '',

            moveTo: '',
            redirectTo: false,
            loader: false,
            options: {
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              },
            monthlyCases: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                  {
                    label: 'Monthly Cases',
                    data: [],
                    backgroundColor: 'rgb(60, 179, 113)',
                  },
                ],
            },
            dailyCases: {
                labels: [],
                datasets: [
                  {
                    label: 'Daily Cases',
                    data: [],
                    backgroundColor: 'rgb(255, 165, 0)',
                  },
                ],
            },
            caseDataAnalytics: {
                labels: ['UnAssigned Cases', 'Pending Cases', 'Verified Cases'],
                datasets: [
                  {
                    label: '# of Cases',
                    data: [0, 0, 0],
                    backgroundColor: [
                      '#FF0000',
                      '#FFD700',
                      '#008000',
                    ],
                    borderColor: [
                      '#FF0000',
                      '#FFD700',
                      '#008000',
                    ],
                    borderWidth: 1,
                  },
                ],
            },
            approveRejectedCaseDataAnalytics: {
                labels: ['Approved Cases', 'Rejected Cases'],
                datasets: [
                  {
                    label: '# of Cases',
                    data: [0, 0],
                    backgroundColor: [
                      '#008000',  
                      '#FF0000',
                    ],
                    borderColor: [
                      '#008000',
                      '#FF0000',
                    ],
                    borderWidth: 1,
                  },
                ],
            }
        };
        this.togglemodal.bind(this);
    }

    componentDidMount() {
        // let comp = this

        // comp.setState({
        //     loader: true
        // })

    //     var state = localStorage.getItem("state")
    //     if(state === null) {
    //         state = sessionStorage.getItem("state")
    //     }

    //     var currentDay = new Date()
    //     var currentMonth = currentDay.getMonth() + 1
    //     if(currentMonth < 10) {
    //         currentMonth = "0" + currentMonth
    //     }
    //     var currentYear = currentDay.getFullYear()
    
    //     var thisMonth = currentYear + "-" + currentMonth

    //     var firstDayMonth = formatDate(new Date(today.getFullYear(), today.getMonth(), 1))
    //     var lastDayMonth = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0))

    //     let activeExecutives = 0
    //     let inActiveExecutives = 0

    //     console.log(state.split(",").slice(0, -1));




    //     firebase.firestore().collection("Case")
    //    .where("State", "array-contains-any", state.split(",").slice(0, -1))
    //     .get().then((querySnapshot) => {
    //         let unAssignedCases = 0
    //         let assignedCases = 0
    //         let verifiedCases = 0
    //         let totalCases = 0
    //         let hpCases = 0
    //         let pendingHPCases = 0
    //         let verfiedHPCases = 0
    //         querySnapshot.forEach(function(doc) { 
    //             if(formatDate(doc.data().CreatedAt) === currentDate) {
    //                 totalCases = parseInt(totalCases) + 1

    //                 if(doc.data().Status === "Pending" && doc.data().AgentName  === "") {
    //                     unAssignedCases = parseInt(unAssignedCases) + 1
    //                 }

    //                 if(doc.data().Status === "Pending" && doc.data().AgentName  !== "") {
    //                     assignedCases = parseInt(assignedCases) + 1
    //                 }

    //                 if(doc.data().Status === "Completed") {
    //                     verifiedCases = parseInt(verifiedCases) + 1
    //                 }
    //             }
    //         })
    //     })

            // comp.setState({
            //     unAssignedCases: unAssignedCases,
            //     assignedCases: assignedCases,
            //     verifiedCases: verifiedCases,
            //     totalCases: totalCases,
            //     hpCases: hpCases,
            //     pendingHPCases: pendingHPCases,
            //     verfiedHPCases: verfiedHPCases,
            //     loader: false
            // })

        // firebase.firestore().collection("Case")
        // .where("Status", "==", "Pending")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        // assignedCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             if(doc.data().AgentName !== "") {
        //                 assignedCases = parseInt(assignedCases) + 1
        //             }
        //         }
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("Status", "==", "Completed")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        // verifiedCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             verifiedCases = parseInt(verifiedCases) + 1
        //         }
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        // totalCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             totalCases = parseInt(totalCases) + 1
        //         }
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("HP", "==", "YES")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        //  hpCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             hpCases = parseInt(hpCases) + 1
        //         }
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("HP", "==", "YES")
        // .where("Status", "==", "Pending")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        //  pendingHPCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             pendingHPCases = parseInt(pendingHPCases) + 1
        //         }
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("HP", "==", "YES")
        // .where("Status", "==", "Completed")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        //  verfiedHPCases = 0
        //     querySnapshot.forEach(function(doc) { 
        //         if(formatDate(doc.data().CreatedAt) === currentDate) {
        //             verfiedHPCases = parseInt(verfiedHPCases) + 1
        //         }
        //     })
        // })

        // firebase.firestore().collection("Attendance")
        // .orderBy("InTimeDate").startAt(firstDayMonth).endAt(lastDayMonth)
        // .get().then((querySnapshot) => {
        //     let totalAgent = 0
        //     querySnapshot.forEach(function(doc) { 
        //         totalAgent = parseInt(totalAgent) + 1
        //     })
        

        //     firebase.firestore().collection("Attendance")
        //     .where("InTimeDate", "==", currentDate)
        //     .get().then((querySnapshot) => {
        //         activeExecutives = 0
        //         querySnapshot.forEach(function(doc) { 
        //             activeExecutives = parseInt(activeExecutives) + 1
        //         })

        //         inActiveExecutives = parseInt(totalAgent) - parseInt(activeExecutives)
        //     })
        //     comp.setState({
        //         activeExecutives: activeExecutives,
        //         inActiveExecutives: inActiveExecutives
        //     })
        // })
    


        // firebase.firestore().collection("Case")
        // .where("Status", "==", "Pending")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        // let followUp = 0
        //     querySnapshot.forEach(function(doc) { 

        //         if(doc.data().CreatedAt !== undefined && doc.data().AssignedAt !== undefined) {
        //             var timeDiff = timeDifference(doc.data().CreatedAt, doc.data().AssignedAt)
    
        //             if(timeDiff <=3) {
        //                 followUp = parseInt(followUp) + 1
        //             }
        //         }
        //     })

        //     comp.setState({
        //         followUp: followUp
        //     })
        // })

        // firebase.firestore().collection("Case")
        // .where("Status", "==", "Completed")
        // .where("State", "array-contains-any", state.split(",").slice(0, -1))
        // .get().then((querySnapshot) => {
        // let tatReport = 0
        //     querySnapshot.forEach(function(doc) { 

        //         if(doc.data().CreatedAt !== undefined && doc.data().AssignedAt !== undefined) {
        //             var timeDiff = timeDifference(doc.data().CreatedAt, doc.data().AssignedAt)
    
        //             if(timeDiff <=3) {
        //                 tatReport = parseInt(tatReport) + 1
        //             }
        //         }
        //     })

        //     comp.setState({
        //         tatReport: tatReport
        //     })
        // })


    // }, 10000)

    this.fetchList();
}

handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
        sessionExpired();
      } 
  }

  async fetchList() {
    let comp = this;

    var today = new Date()
    var currentDate = formatDate(today)

    this.casesDataAnalytics(currentDate, currentDate)
  }

  async casesDataAnalytics(startDate, endDate) {
    let comp = this;

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    let bank = localStorage.getItem("bank")
    if (bank === null) {
        bank = sessionStorage.getItem("bank")
    }

    var jsonData;

    if (!bank.includes("SUPER ADMIN")) {
        jsonData = JSON.stringify({
            "startDate": startDate,
            "endDate": endDate,
            "state": "SUPER ADMIN",
            "bank": bank.split(",").slice(0, -1),
        });
    }

    if(bank.includes("SUPER ADMIN")){
        jsonData = JSON.stringify({
            "startDate": startDate,
            "endDate": endDate,
            "state": state.split(",").slice(0, -1),
        });
    }

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/dashboard/case',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
              comp.setState({
                unAssignedCases: response.data.unAssignedCases,
                assignedCases: response.data.pendingCases,
                verifiedCases: response.data.verifiedCases,
                totalCases: response.data.totalCases,
                caseDataAnalytics: {
                    labels: ['UnAssigned Cases', 'Pending Cases', 'Verified Cases'],
                    datasets: [
                      {
                        label: '# of Cases',
                        data: [response.data.unAssignedCases, response.data.pendingCases, response.data.verifiedCases],
                        backgroundColor: [
                          '#FF0000',
                          '#FFD700',
                          '#008000',
                        ],
                        borderColor: [
                          '#FF0000',
                          '#FFD700',
                          '#008000',
                        ],
                        borderWidth: 1,
                      },
                    ],
                }
            })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    this.approvedRejectedCases(startDate, endDate)
  }  

  async approvedRejectedCases(startDate, endDate) {
    let comp = this;

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    let bank = localStorage.getItem("bank")
    if (bank === null) {
        bank = sessionStorage.getItem("bank")
    }

    var jsonData;

    // var jsonData = JSON.stringify({
    //     "startDate": startDate,
    //     "endDate": endDate,
    //     "state": state.split(",").slice(0, -1),
    // });

    if (!bank.includes("SUPER ADMIN")) {
        jsonData = JSON.stringify({
            "startDate": startDate,
            "endDate": endDate,
            "state": "SUPER ADMIN",
            "bank": bank.split(",").slice(0, -1),
        });
    }

    if(bank.includes("SUPER ADMIN")){
        jsonData = JSON.stringify({
            "startDate": startDate,
            "endDate": endDate,
            "state": state.split(",").slice(0, -1),
        });
    }

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/dashboard/positiveNegativeCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
              comp.setState({
                approveRejectedCaseDataAnalytics: {
                    labels: ['Approved Status', 'Rejected Status', "Other Status"],
                    datasets: [
                      {
                        label: '# of Cases',
                        data: [response.data.positive, response.data.negative, response.data.other],
                        backgroundColor: [
                          '#008000',  
                          '#FF0000',
                        ],
                        borderColor: [
                          '#008000',
                          '#FF0000',
                        ],
                        borderWidth: 1,
                      },
                    ],
                }
            })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    this.weeklyWiseChart()
  } 

  async weeklyWiseChart() {
    let comp = this;

    let casesDayWise = Array(7).fill(0)
    let currentWeekNames = Array(7).fill('')
    let currentDate = new Date()
    var currentWeek = currentWeekDates(currentDate) 

    var jsonData = JSON.stringify({
        "startDate": formatDate(currentWeek[0]),
        "endDate": formatDate(currentWeek[currentWeek.length - 1])
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/dashboard/dailyCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
            for(let i = 0; i< currentWeek.length; i++) {
                currentWeekNames[i] = currentWeek[i].toString().split(' ').slice(1, 3).join(' ')
                for(let j=0; j< response.data.length; j++) {
                    if(currentWeek[i].getDate() == response.data[j].day && currentWeek[i].getMonth() + 1 == response.data[j].month) {
                        casesDayWise[i] = response.data[j].count
                    }
                }
            }

            comp.setState({
                dailyCases: {
                    labels: currentWeekNames,
                    datasets: [
                      {
                        label: 'Daily Cases',
                        data: casesDayWise,
                        backgroundColor: 'rgb(255, 165, 0)',
                      },
                    ],
                  },
            })
        }
    })
    .catch(function (error) {
      console.log(error);
    //   comp.handleNon200Response(error, "1");
    });

    this.monthlyWiseChart()
  }

  async monthlyWiseChart() {
    let comp = this;

    let casesMonthlyWise = Array(12).fill(0)
    let currentDate = new Date()
    var firstDateOftheYear = formatDate(new Date(currentDate.getFullYear(), 0, 1))
    var lastDateOftheYear = formatDate(new Date(currentDate.getFullYear(), 11, 31))

    var jsonData = JSON.stringify({
        "startDate": firstDateOftheYear,
        "endDate": lastDateOftheYear
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/dashboard/monthlyCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
            for(let i = 0; i< casesMonthlyWise.length; i++) {
                for(let j=0; j< response.data.length; j++) {
                    if(response.data[j] !== undefined && i+1 == response.data[j].month) {
                        casesMonthlyWise[i] = response.data[j].count
                    }
                }
            }

            comp.setState({
                monthlyCases: {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [
                      {
                        label: 'Monthly Cases',
                        data: casesMonthlyWise,
                        backgroundColor: 'rgb(60, 179, 113)',
                      },
                    ],
                  },
                  year: currentDate.getFullYear()
            })

        }
    })
    .catch(function (error) {
      console.log(error);
    //   comp.handleNon200Response(error, "1");
    });

    var firstDateOftheMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    var lastDateOftheMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
    this.casesBankWise(firstDateOftheMonth, lastDateOftheMonth)
  }

  async casesBankWise(firstDateOftheMonth, lastDateOftheMonth) {
    let comp = this;

    let banks = []
    let banksCaseWise = []
    let bankAPINames = []

    var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/bank',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
        }
      };
      
      axios(config)
      .then(function (response) {
            for(let i=0; i < response.data.length; i++) {
                banks.push(response.data[i].name)
            }


            var jsonData1 = JSON.stringify({
                "startDate": firstDateOftheMonth,
                "endDate": lastDateOftheMonth
            });

            var config1 = {
                method: 'post',
                url: API_BASE_URL + '/auth/dashboard/caseBankWise',
                headers: { 
                  apikey: API_KEY, 
                  Authorization: "Bearer " + getCookie("at"),
                  'Content-Type': 'application/json'
                },
                data: jsonData1
            };
        
            axios(config1)
            .then(function (response1) {
                console.log("case bank wise api", response1.data)
                console.log("bank names", banks)
                if(response.status == 200) {
                    for(let i = 0; i < response1.data.length; i++) {
                        const lock = {
                            name: response1.data[i]._id,
                            unAssignedCases:response1.data[i].unAssignedCases ,
                            pendingCases:response1.data[i].pendingCases ,
                            verifiedCases:response1.data[i].verifiedCases ,
                            totalCases:response1.data[i].totalCases ,
                        }
                        banksCaseWise.push(lock)
                        bankAPINames.push(response1.data[i]._id)
                    }

                    for(let j = 0; j < banks.length; j++) {
                        if(bankAPINames.indexOf(banks[j]) == -1) {
                            const lock = {
                                name: banks[j],
                                unAssignedCases: 0,
                                pendingCases: 0,
                                verifiedCases: 0,
                                totalCases: 0
                            }
                            banksCaseWise.push(lock)
                        }
                    }

                    console.log("banksCaseWise", banksCaseWise)

                    comp.setState({
                        banksCaseWise: banksCaseWise,
                        commonStartDate: firstDateOftheMonth,
                        commonEndDate: lastDateOftheMonth
                    })
                }
            })

    })
    .catch(function (error) {
      console.log(error);
    //   comp.handleNon200Response(error, "1");
    });
    this.casesStateWise(firstDateOftheMonth, lastDateOftheMonth)
  }

  async casesStateWise(firstDateOftheMonth, lastDateOftheMonth) {
    let comp = this;

    let states = []
    let statesCaseWise = []
    let stateAPINames = []

    var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/state',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
        }
      };
      
      axios(config)
      .then(function (response) {
            for(let i=0; i < response.data.length; i++) {
                states.push(response.data[i].name)
            }

            var jsonData1 = JSON.stringify({
                "startDate": firstDateOftheMonth,
                "endDate": lastDateOftheMonth
            });

            var config1 = {
                method: 'post',
                url: API_BASE_URL + '/auth/dashboard/caseStateWise',
                headers: { 
                  apikey: API_KEY, 
                  Authorization: "Bearer " + getCookie("at"),
                  'Content-Type': 'application/json'
                },
                data: jsonData1
            };
        
            axios(config1)
            .then(function (response1) {
                console.log("case state wise api", response1.data)
                console.log("state names", states)
                if(response.status == 200) {
                    for(let i = 0; i < response1.data.length; i++) {
                        const lock = {
                            name: response1.data[i]._id,
                            unAssignedCases:response1.data[i].unAssignedCases ,
                            pendingCases:response1.data[i].pendingCases ,
                            verifiedCases:response1.data[i].verifiedCases ,
                            totalCases:response1.data[i].totalCases ,
                        }
                        statesCaseWise.push(lock)
                        stateAPINames.push(response1.data[i]._id)
                    }

                    for(let j = 0; j < states.length; j++) {
                        if(stateAPINames.indexOf(states[j]) == -1) {
                            const lock = {
                                name: states[j],
                                unAssignedCases: 0,
                                pendingCases: 0,
                                verifiedCases: 0,
                                totalCases: 0
                            }
                            statesCaseWise.push(lock)
                        }
                    }

                    console.log("statesCaseWise", statesCaseWise)

                    comp.setState({
                        statesCaseWise: statesCaseWise,
                        commonStartDate: firstDateOftheMonth,
                        commonEndDate: lastDateOftheMonth
                    })
                }
            })

    })
    .catch(function (error) {
      console.log(error);
    //   comp.handleNon200Response(error, "1");
    });
  }



    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    moveToParticularPage(pagePath) {
        this.setState({
            moveTo: pagePath,
            redirectTo: true
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    filterWeeklyCase(e) {
        let comp = this;

        if(this.state.startDate == '') {
            alert("Select Start Date");
            return;
        }

        if(this.state.endDate == '') {
            alert("Select End Date");
            return;
        }

        let casesDayWise = Array(7).fill(0)
        let currentWeekNames = Array(7).fill('')
        let currentDate = new Date()
        var currentWeek = currentWeekDates(new Date(this.state.startDate)) 
    
        var jsonData = JSON.stringify({
            "startDate": formatDate(currentWeek[0]),
            "endDate": formatDate(currentWeek[currentWeek.length - 1])
        });
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/dashboard/dailyCase',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            if(response.status == 200) {
                for(let i = 0; i< currentWeek.length; i++) {
                    currentWeekNames[i] = currentWeek[i].toString().split(' ').slice(1, 3).join(' ')
                    for(let j=0; j< response.data.length; j++) {
                        if(currentWeek[i].getDate() == response.data[j].day && currentWeek[i].getMonth() + 1 == response.data[j].month) {
                            casesDayWise[i] = response.data[j].count
                        }
                    }
                }
    
                comp.setState({
                    dailyCases: {
                        labels: currentWeekNames,
                        datasets: [
                          {
                            label: 'Daily Cases',
                            data: casesDayWise,
                            backgroundColor: 'rgb(255, 165, 0)',
                          },
                        ],
                      },
                })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "1");
        });
    }

    filterMonthlyCase(e) {
        let comp = this
        if(this.state.year == '') {
            alert("Select Year");
            return;
        }

        let casesMonthlyWise = Array(12).fill(0)
        let currentDate = new Date()
        var firstDateOftheYear = formatDate(new Date(this.state.year, 0, 1))
        var lastDateOftheYear = formatDate(new Date(this.state.year, 11, 31))
    
        var jsonData = JSON.stringify({
            "startDate": firstDateOftheYear,
            "endDate": lastDateOftheYear
        });
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/dashboard/monthlyCase',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            if(response.status == 200) {
                for(let i = 0; i< casesMonthlyWise.length; i++) {
                    for(let j=0; j< response.data.length; j++) {
                        if(response.data[j] !== undefined && i+1 == response.data[j].month) {
                            casesMonthlyWise[i] = response.data[j].count
                        }
                    }
                }
    
                comp.setState({
                    monthlyCases: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        datasets: [
                          {
                            label: 'Monthly Cases',
                            data: casesMonthlyWise,
                            backgroundColor: 'rgb(60, 179, 113)',
                          },
                        ],
                      },
                })
    
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "1");
        });
    }

    filterCommonDates(e) {
        let comp = this;
    
        if(this.state.commonStartDate == '') {
            alert("Select Start Date");
            return;
        }
    
        if(this.state.commonEndDate == '') {
            alert("Select End Date");
            return;
        }

        this.casesBankWise(this.state.commonStartDate, this.state.commonEndDate)
    }

    filterCaseAnalytics(e) {
        let comp = this;
    
        if(this.state.analyticsStartDate == '') {
            alert("Select Start Date");
            return;
        }
    
        if(this.state.analyticsEndDate == '') {
            alert("Select End Date");
            return;
        }

        this.casesDataAnalytics(this.state.analyticsStartDate, this.state.analyticsEndDate)
    }

    render() {

        var position = localStorage.getItem("position")
        if(position === null) {
            position = sessionStorage.getItem("position")
        }
        return (
            <React.Fragment>
                {this.state.redirectTo ? <Redirect to = {this.state.moveTo} /> : null }

                {this.state.loader ? 
                     <Loader 
                        type="Circles" 
                        color="#4058b0" 
                        height={130} 
                        width={130}
                        timeout={10000}
                        style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2 }}
                    /> : null }
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        {/* <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Dashboard')} /> */}
                                <Row>
                                    <Col md="12">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <Row>
                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.analyticsStartDate} id = "analyticsStartDate" name = "analyticsStartDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.analyticsEndDate} id = "analyticsEndDate" name = "analyticsEndDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md = {{size: 4}} style = {{margin: "auto"}}>
                                                                <Button color="primary" onClick = {this.filterCaseAnalytics.bind(this)} type="submit">Filter</Button>
                                                            </Col>
                                                        </Row>
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                        </Col>
                                    </Row>

                        <Row>
                            {/* <Col xl="4">
                                <WelcomeComp />
                                <MonthlyEarning />
                            </Col> */}
                            <Col xl="12">
                                <Row>
                                    {/* Reports Render */}
                                            <Col md="4">
                                                <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/unassigned-case")}>
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">UnAssigned Cases</p>
                                                                <h4 className="mb-0">{this.state.unAssignedCases}</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                <span className="avatar-title">
                                                                    <i className={"bx " + "bx-archive-in" + " font-size-24"}></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/pending-case")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Pending Cases</p>
                                                            <h4 className="mb-0">{this.state.assignedCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col>

                                            <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/verified-case")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Verified Cases</p>
                                                            <h4 className="mb-0">{this.state.verifiedCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col>

                                            <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/case-report")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Total Cases</p>
                                                            <h4 className="mb-0">{this.state.totalCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col>

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/hp-case")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">High Priority Cases</p>
                                                            <h4 className="mb-0">{this.state.hpCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/pending-hp-case")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Pending HP Cases</p>
                                                            <h4 className="mb-0">{this.state.pendingHPCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/verified-hp-case")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Verified HP Cases</p>
                                                            <h4 className="mb-0">{this.state.verfiedHPCases}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Follow Up</p>
                                                            <h4 className="mb-0">{this.state.followUp}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/view-tat-details")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">TAT Report</p>
                                                            <h4 className="mb-0">{this.state.tatReport}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}

                                            {/* <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/attendance-report")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Number of Active Executives</p>
                                                            <h4 className="mb-0">{this.state.activeExecutives}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col>

                                            <Col md="4">
                                            <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/")}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">Number of InActive Executives</p>
                                                            <h4 className="mb-0">{this.state.inActiveExecutives}</h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            </Col> */}
                                </Row>
                        

                                {/* <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 float-sm-left">
                                            Email Sent
                                        </CardTitle>
                                        <div className="float-sm-right">
                                            <ul className="nav nav-pills">
                                                {
                                                    this.state.email.map((mail, key) =>
                                                        <li className="nav-item" key={"_li_" + key}>
                                                            <Link className={mail.isActive ? "nav-link active" : "nav-link"} to={mail.linkto}>{mail.title}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                        <StackedColumnChart />
                                    </CardBody>
                                </Card> */}
                            </Col>


                            <Col xl="4">
                                {/* <ActivityComp /> */}
                            </Col>
                        </Row>

                        <br />

                        <Row>
                            <Col md = {6}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <Pie data = {this.state.caseDataAnalytics} height = "300px" width= "300px" options={{maintainAspectRatio: false}}/>
                                            </Media> 
                                        </Media> 
                                    </CardBody>
                                 </Card>       
                            </Col>
                            <Col md = {6}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <Pie data = {this.state.approveRejectedCaseDataAnalytics} height = "300px" width= "300px" options={{maintainAspectRatio: false}}/>
                                            </Media> 
                                        </Media> 
                                    </CardBody>
                                 </Card>       
                            </Col>
                        </Row>

                        <br />
                        <br />


                                {position == "Super Admin" ? <>              

                                <Row>
                                    <Col md="6">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <Row>
                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md = {{size: 4}} style = {{margin: "auto"}}>
                                                                <Button color="primary" onClick = {this.filterWeeklyCase.bind(this)} type="submit">Filter</Button>
                                                            </Col>
                                                            <Col md = {{size: 12}}>
                                                                <p>Please select 7 days range time period</p>
                                                            </Col>
                                                        </Row>
                                                        <Bar data={this.state.dailyCases} width={100} height={50} options={this.state.options} />
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <Row>
                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">Year<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="select"  value = {this.state.year} id = "year" name = "year" onChange = {this.handleChange.bind(this)}>
                                                                        <option value = "">Select Year</option>
                                                                        <option value = "2022">2022</option>
                                                                        <option value = "2023">2023</option>
                                                                        <option value = "2024">2024</option>
                                                                        <option value = "2025">2025</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md = {{size: 4}} style = {{margin: "auto"}}>
                                                                <Button color="primary" onClick = {this.filterMonthlyCase.bind(this)} type="submit">Filter</Button>
                                                            </Col>
                                                            <Col md = {{size: 12}}>
                                                                <p>Year: {this.state.year}</p>
                                                            </Col>
                                                        </Row>
                                                        <Bar data={this.state.monthlyCases} width={100} height={50} options={this.state.options} />
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <Row>
                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.commonStartDate} id = "commonStartDate" name = "commonStartDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md = {{size: 4}}>
                                                                <FormGroup>
                                                                    <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                                    <Input type="date"  value = {this.state.commonEndDate} id = "commonEndDate" name = "commonEndDate" onChange = {this.handleChange.bind(this)}>
                                                                </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md = {{size: 4}} style = {{margin: "auto"}}>
                                                                <Button color="primary" onClick = {this.filterCommonDates.bind(this)} type="submit">Filter</Button>
                                                            </Col>
                                                        </Row>
                                                        <br />

                                                        <Row>
                                                            <Col md = {6}>
                                                                <h4>Bank Wise Cases</h4> <br />
                                                                <div className="table-responsive" data-pattern="priority-columns">
                                                                    <Table className="table mb-0 table-striped table-bordered" style={{height: "500px", overFlowY: "scroll", display: "block"}}>
                                                                        <thead>
                                                                            <tr>
                                                                                    <th>Bank</th>
                                                                                    <th>UnAssigned Cases</th>
                                                                                    <th>Pending Cases</th>
                                                                                    <th>Verified Cases</th>
                                                                                    <th>Total Cases</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.banksCaseWise.map((rowData, index) => (
                                                                            <tr key = {index}>
                                                                                <td>{rowData.name}</td>
                                                                                <td>{rowData.unAssignedCases}</td>
                                                                                <td>{rowData.pendingCases}</td>
                                                                                <td>{rowData.verifiedCases}</td>
                                                                                <td>{rowData.totalCases}</td>
                                                                            </tr>
                                                                        ))}
                                                                        
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>

                                                            <Col md = {6}>
                                                                <h4>State Wise Cases</h4> <br />
                                                                <div className="table-responsive" data-pattern="priority-columns">
                                                                    <Table className="table mb-0 table-striped table-bordered" style={{height: "500px", overFlowY: "scroll", display: "block"}}>
                                                                        <thead>
                                                                            <tr>
                                                                                    <th>State</th>
                                                                                    <th>UnAssigned Cases</th>
                                                                                    <th>Pending Cases</th>
                                                                                    <th>Verified Cases</th>
                                                                                    <th>Total Cases</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {this.state.statesCaseWise.map((rowData, index) => (
                                                                            <tr key = {index}>
                                                                                <td>{rowData.name}</td>
                                                                                <td>{rowData.unAssignedCases}</td>
                                                                                <td>{rowData.pendingCases}</td>
                                                                                <td>{rowData.verifiedCases}</td>
                                                                                <td>{rowData.totalCases}</td>
                                                                            </tr>
                                                                        ))}
                                                                        
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row> </> : null }

                        <Row>
                            <Col xl="4">
                                {/* <SocialSource /> */}
                            </Col>
               

                            <Col xl="4">
                                {/* <TopCities /> */}
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                {/* <LatestTranaction /> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            Order Details
                        </ModalHeader >
                        <ModalBody>
                            <p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
                            <p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

                            <div className="table-responsive">
                                <Table className="table table-centered table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage1} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                    <p className="text-muted mb-0">$ 225 x 1</p>
                                                </div>
                                            </td>
                                            <td>$ 255</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage2} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                                                    <p className="text-muted mb-0">$ 145 x 1</p>
                                                </div>
                                            </td>
                                            <td>$ 145</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Sub Total:</h6>
                                            </td>
                                            <td>$ 400</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Shipping:</h6>
                                            </td>
                                            <td>Free</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Total:</h6>
                                            </td>
                                            <td>$ 400</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                        </ModalFooter>
                    </div>
                </Modal> */}
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
