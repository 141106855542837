import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import Modal from "reactstrap/lib/Modal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

const customStyles1 = {
  control: base => ({
    ...base,
    height: 53,
    minHeight: 35,
    backgroundColor: "white",
    overflow: "hidden",
    width: 250
  })
};


class ClientReport extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.getCaseDetails = this.getCaseDetails.bind(this)
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleSubmit,
        '3': this.handleFilter,
        '4': this.getCaseDetails
    };
    this.state = {
      firebaseData: [],
      firebaseData1: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      banks: [],
      products: [],
      bank: '',
      product: '',
      agentName: '',
      agent: '',
      topIndex: '-1',
      startDate: '',
      endDate: '',
      openModal: false,
      applicationId: '',
      customerName: '',
      mobileNumber: '',
      productName: '' ,
      rvAddress: '',
      bvAddress: '',
      pvAddress: '',
      selectedMulti: [],

      pages: [30, 50, 100, 300, 1000],
      page: 0,
      rowsPerPage: 30,
      lastVisible: {},
      count: 0
    };
  }

  componentDidMount() {
    let comp = this
    let count = 0

    var today = new Date()
    var currentDate = formatDate(today)
    
    
    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    comp.fetchList()

    // firebase.firestore().collection("Case")
    // .where("Status", "==", "Pending")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().AgentName !== "") {
    //         if(typeof doc.data().CreatedAt == "string") {
    //           if(formatDate(doc.data().CreatedAt) === currentDate) {
    //             count = count + 1
    //           }
    //         }
    //       }
    //     })
    //     comp.setState({
    //         count: count
    //     })
    // })


    // firebase.firestore().collection("Case")
    // .where("Status", "==", "Pending")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .limit(this.state.rowsPerPage)
    // .get().then((querySnapshot) => {
    //     var loans = [];

    //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
    //     console.log("last", lastVisible);


    //     querySnapshot.forEach(function(doc) {
    //         if(doc.data().AgentName !== "") {
    //           if(typeof doc.data().CreatedAt == "string") {
    //             if(formatDate(doc.data().CreatedAt) === currentDate) {
    //             loans.push(doc.data())
    //             }
    //           }
    //         }
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //       lastVisible: lastVisible
    //     })
    // });

    // firebase.firestore().collection("Case")
    // .where("Status", "==", "Pending")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().AgentName !== "") {
    //         if(typeof doc.data().CreatedAt == "string") {
    //           const loan = {
    //             ApplicationId: doc.data().ApplicationId,
    //             CustomerName: doc.data().CustomerName,
    //             Bank: doc.data().Bank,
    //             Product: doc.data().ProductName,
    //             CaseType: doc.data().FI,
    //             TATTime: doc.data().TATTime,
    //             Agent: doc.data().AgentName,
    //             Status: doc.data().Status,
    //             Allocation_Date_Time: doc.data().CreatedAt,
    //           }
    //           loans.push(loan)
    //         }
    //         }
    //     });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });

    // firebase.firestore().collection("LoanProduct").orderBy("Name")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       const lock = {
    //         Name: doc.data().Name,
    //         Status: doc.data().Status,
    //         PushId: doc.id
    //       }
    //       loans.push(lock)
    //     });
    //     comp.setState({
    //       products: loans
    //     })
    // });

    // firebase.firestore().collection("Agents")
    // .where("ApprovalStatus", "==", "Approved")
    // .get().then((querySnapshot) => {
    //     var ags = [];
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().AgentId !== undefined) {
    //         const dict = {
    //           label: (doc.data().AgentId) + "-" + doc.data().Name,
    //           value: doc.data().UserId
    //         }
    //         ags.push(dict)
    //       }
    //     });
    //     comp.setState({
    //       agents: ags,
    //     })
    // });
  }

  async fetchList() {
    let comp = this
    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        banks: response1.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

//   handleChangePage(event, newPage) {

//     var state = localStorage.getItem("state")
//     if(state === null) {
//         state = sessionStorage.getItem("state")
//     }

//     var today = new Date()
//     var currentDate = formatDate(today)

//     let comp = this
//       this.setState({
//           page: newPage
//       })

//     var loans = [];

//     for(let i = 0; i< this.state.firebaseData.length; i++) {
//         loans.push(this.state.firebaseData[i])
//     }


//     firebase.firestore().collection("Case")
//     .where("Status", "==", "Pending")
//     .where("State", "array-contains-any", state.split(",").slice(0, -1))
//     .orderBy("TimeStamp", "desc")
//     .limit(this.state.rowsPerPage)
//     .startAfter(this.state.lastVisible)
//     .get().then((querySnapshot) => {

//         var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
//         console.log("last", lastVisible);


//         querySnapshot.forEach(function(doc) {
//             if(doc.data().AgentName !== "") {
//               if(typeof doc.data().CreatedAt == "string") {
//                 if(formatDate(doc.data().CreatedAt) === currentDate) {
//                 loans.push(doc.data())
//                 }
//               }
//             }
//         });
//         comp.setState({
//           firebaseData: loans,
//           lastVisible: lastVisible
//         })
//     });
//   }
  
//   handleChangeRowsPerPage(event) {

//     var state = localStorage.getItem("state")
//     if(state === null) {
//         state = sessionStorage.getItem("state")
//     }

//       let comp = this

//      this.setState({
//          rowsPerPage: parseInt(event.target.value, 10),
//          page: 0
//      })

//      var today = new Date()
//      var currentDate = formatDate(today)    
 
//      firebase.firestore().collection("Case")
//      .where("Status", "==", "Pending")
//      .where("State", "array-contains-any", state.split(",").slice(0, -1))
//      .orderBy("TimeStamp", "desc")
//      .limit(parseInt(event.target.value, 10))
//      .get().then((querySnapshot) => {
//          var loans = [];
 
//          var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
//          console.log("last", lastVisible);

//          querySnapshot.forEach(function(doc) {
//              if(doc.data().AgentName !== "") {
//                if(typeof doc.data().CreatedAt == "string") {
//                  if(formatDate(doc.data().CreatedAt) === currentDate) {
//                  loans.push(doc.data())
//                  }
//                }
//              }
//          });
//          comp.setState({
//            firebaseData: loans,
//            lastVisible: lastVisible
//          })
//      });
//     }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state
    let comp = this


    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    if(data.bank === '') {
        alert("Select Bank")
        document.getElementById("bank").focus();
        return
    }

    var jsonData = JSON.stringify({
      bank: data.bank
    })

    var config1 = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/salesCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };
    
    axios(config1)
    .then(function (response1) {
      console.log(response1.data)
      let downloadData = []
      if(response1.status == 200) {
        for(let i=0; i< response1.data.length; i++) {
          const loan = {
            ApplicationId: response1.data[i].applicationid,
            CustomerName: response1.data[i].customername,
            Bank: response1.data[i].bank,
            Product: response1.data[i].productname,
            CaseType: response1.data[i].fi,
            TATTime: response1.data[i].tattime,
            Agent: response1.data[i].agentname,
            Status: response1.data[i].status,
            Allocation_Date_Time: response1.data[i].createdat.slice(0, 10),
            CPV_Status: (response1.data[i].qcpvstatus == undefined) ? "": response1.data[i].qcpvstatus,
            Reject_Reason:(response1.data[i].qrejectreason == undefined) ? "": response1.data[i].qrejectreason,
          }
          downloadData.push(loan)
        }
        comp.setState({
          firebaseData: response1.data,
          firebaseData1: downloadData
        })
      }
    })
    .catch(function (error) {
      console.log(error.response.data);
      comp.handleNon200Response(error, "2");
    });


    // firebase.firestore().collection("SalesCase").doc("Bank").collection(data.bank + "-CASE")
    //   .get().then((querySnapshot) => {
    //       var loans = [];
    //       querySnapshot.forEach(function(doc) {
    //             loans.push(doc.data())
    //       });
    //       comp.setState({
    //         firebaseData: loans
    //       })
    //   });

    // firebase.firestore().collection("SalesCase").doc("Bank").collection(data.bank + "-CASE")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //           const loan = {
    //             ApplicationId: doc.data().ApplicationId,
    //             CustomerName: doc.data().CustomerName,
    //             Bank: doc.data().Bank,
    //             Product: doc.data().ProductName,
    //             CaseType: doc.data().FI,
    //             TATTime: doc.data().TATTime,
    //             Status: doc.data().Status,
    //             Allocation_Date_Time: doc.data().CreatedAt,
    //             CPV_Status: (doc.data().QCpvStatus == undefined) ? "": doc.data().QCpvStatus,
    //             Reject_Reason:(doc.data().QRejectReason == undefined) ? "": doc.data().QRejectReason,
    //           }
    //           loans.push(loan)
    //     });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });
}

handleFilter(e) {
  e.preventDefault();

  let data = this.state
  let comp = this


  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  if(data.bank === '') {
    alert("Select Bank")
    document.getElementById("bank").focus();
    return
}

  if(data.startDate === '') {
    alert("Select Start date");
    document.getElementById("startDate").focus();
    return;
  }

  if(data.endDate === '') {
    alert("Select End Date");
    document.getElementById("endDate").focus();
    return;
  }

  var jsonData = JSON.stringify({
    bank: data.bank,
    startDate: data.startDate,
    endDate: data.endDate
  })

  var config1 = {
    method: 'post',
    url: API_BASE_URL + '/auth/report/salesCase',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
    data: jsonData
  };
  
  axios(config1)
  .then(function (response1) {
    comp.setState({
      firebaseData: response1.data
    })
  })
  .catch(function (error) {
    console.log(error);
    comp.handleNon200Response(error, "2");
  });

  // firebase.firestore().collection("SalesCase").doc("Bank").collection(data.bank + "-CASE")
  // .get().then((querySnapshot) => {
  //     var loans = [];
  //     querySnapshot.forEach(function(doc) {
  //         if(typeof doc.data().CreatedAt == "string") {
  //           if((formatDate(doc.data().CreatedAt) >= data.startDate) && (formatDate(doc.data().CreatedAt) <= data.endDate)) {
  //               loans.push(doc.data())
  //           }
  //       }
  //     });
  //     comp.setState({
  //       firebaseData: loans
  //     })
  // });
}

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    td8 = tr[i].getElementsByTagName("td")[8];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  toggle() {
    let comp = this

    comp.setState({
      openModal: false
    })
  }

  getCaseDetails(key) {
    let comp = this

    let rvAddress = []
    let bvAddress = []
    let pvAddress = []

    comp.setState({
      rvAddress: '',
      bvAddress: '',
      pvAddress: ''
    })

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    var jsonData = JSON.stringify({
      state: state.split(",").slice(0, -1),
      applicationId: key.applicationid
    })


    var config = {
      method: "post",
      url: API_BASE_URL + "/auth/case/searchByApplicationId",
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        "Content-Type": "application/json",
      },
      data: jsonData,
    }

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
              rvAddress.push(response.data[i].residenceaddress)
              bvAddress.push(response.data[i].officeaddress)
              pvAddress.push(response.data[i].permanentaddress)
            }

            for(let i = 0; i< rvAddress.length; i++) {
              if(rvAddress[i] !== undefined) {
                comp.setState({
                  rvAddress: rvAddress[i]
                })
              }
            }
      
            for(let i = 0; i< bvAddress.length; i++) {
              if(bvAddress[i] !== undefined) {
                comp.setState({
                  bvAddress: bvAddress[i]
                })
              }
            }
            for(let i = 0; i< pvAddress.length; i++) {
              if(pvAddress[i] !== undefined) {
                comp.setState({
                  pvAddress: pvAddress[i]
                })
              }
            }
          
            comp.setState({
              applicationId: key.applicationid,
              customerName: key.customername,
              mobileNumber: key.mobilenumber,
              productName: key.productname,
              openModal: true
          })
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        comp.handleNon200Response(error, "4")
      })
}

handleMulti = (selectedMulti, index) => {
  this.setState({ 
    selectedMulti,
    topIndex: index
  });
};


exportToCSV(csvData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Reports" breadcrumbItem="Bank Client Report" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData1, "Bank Client Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />

                                          <Row>
                                              <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
        
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                              </Col>
                                              </Row>


                                        <br />
                                        <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilter.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Bank</th>
                                                            <th>Product</th>
                                                            <th>Case Type</th>
                                                            <th>TAT Time</th>
                                                            <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td onClick = {this.getCaseDetails.bind(this, rowData)} style = {{color: "#556EE6", cursor: "pointer"}}>{rowData.applicationid}</td>
                                                        <td>{rowData.customername}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.productname}</td>
                                                        <td>{rowData.fi}</td>
                                                        <td>{rowData.tattime}</td>
                                                        <td>{rowData.status}</td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        {/* <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.count}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          /> */}

                                        <Modal isOpen={this.state.openModal} toggle={this.toggle.bind(this)}>
                                          <ModalHeader toggle={this.toggle.bind(this)}>{this.state.applicationId}</ModalHeader>
                                          <ModalBody>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Customer Name</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.customerName}</h6>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Mobile Number</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.mobileNumber}</h6>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Product</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.productName}</h6>
                                              </Col>
                                            </Row>
                                            {this.state.pvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>PV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.pvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                            {this.state.rvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>RV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.rvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                            {this.state.bvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>BV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.bvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                          </ModalBody>
                                        </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ClientReport;