import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, Input, FormGroup, Label} from "reactstrap";
import Button from "reactstrap/lib/Button";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import { TablePagination } from '@material-ui/core';
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
import axios from "axios";

class AgentAssignState extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.funcMap = {
            '1': this.fetchList,
            '2': this.handleSubmit,
            '98': this.handleChangePage,
            '99': this.handleChangeRowsPerPage
        };
        this.state = {
            firebaseData: [],
            profile: false,
            changedRow: '0',
            agents: [],
            states: [],
            stateName: [],
            agentId: [],
            pages: [30, 50, 100, 300, 1000],
            page: 0,
            rowsPerPage: 30,
            queryParams: {},
            totalRecords: 0
        };

    }

    componentDidMount() {
        this.fetchList();
        let comp = this
        var states = [];

        var config = {
            method: 'get',
            url: API_BASE_URL + '/auth/state',
            headers: { 
              apikey: API_KEY, 
              Authorization: "Bearer " + getCookie("at"),
            }
          };
          
          axios(config)
          .then(function (response) {
              if(response.status == 200) {
                for(let i=0; i< response.data.length; i++) {
                    const newData = {
                        options: [{
                            label: response.data[i].name,
                            value: response.data[i].name,
                        }]
                    }
                    states.push(newData)
                }

                comp.setState({
                    states: states
                })
            }
          })
          .catch(function (error) {
            console.log(error);
            // comp.handleNon200Response(error, "1");
          });

        // firebase.firestore().collection("State").orderBy("Name")
        // .onSnapshot(function(querySnapshot) {
        //     var states = [];
        //     querySnapshot.forEach(function(doc) {
        //         const newData = {
        //             options: [{
        //                 label: doc.data().Name,
        //                 value: doc.data().Name,
        //             }]
        //           }
        //       states.push(newData)
        //     });
        //     comp.setState({
        //       states: states
        //     })
        // });
    }

    async fetchList() {
        let comp = this

        let agSelect = []

        let jsonData = {}

        comp.setState({
          queryParams: jsonData
        })
    
        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/approvedAgents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            if(response.status == 200) {
                for(let i=0; i< response.data.agentsList.length; i++) {
                if(response.data.agentsList[i].agentid !== "") {
                    const dict = {
                      label: (response.data.agentsList[i].agentid) + "-" + response.data.agentsList[i].name,
                      value: response.data.agentsList[i]._id
                    }
                    agSelect.push(dict)
                  }
                }
                  comp.setState({
                    firebaseData: response.data.agentsList,
                    totalRecords: response.data.totalRecords,
                    agents: agSelect
                })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "1");
        });
      }

      handleNon200Response = (error, functionIndex) => {
        if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
          sessionExpired();
        } 
      }

      myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        td4 = tr[i].getElementsByTagName("td")[4];
        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4.textContent || td4.innerText;
        
        var main = txtValue1+ txtValue2+txtValue3+txtValue4;
          if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = this.state;
        let comp = this;

        console.log(data.agentId)

        if(data.agentId.length == 0) {
            alert('Select Agent')
            document.getElementById("agentId").focus();
            return;
        }


        if(data.stateName.length == 0) {
            alert('Select State')
            document.getElementById("stateName").focus();
            return;
        }

        console.log(data.agentId.value)
        console.log(data.stateName.value)

        let st = []

        st.push(data.stateName.value)

        var jsonData = JSON.stringify({
            "state": st,
          })
      
          var config = {
            method: "put",
            url: API_BASE_URL + "/auth/agents/" + data.agentId.value,
            headers: {
              apikey: API_KEY,
              Authorization: "Bearer " + getCookie("at"),
              "Content-Type": "application/json",
            },
            data: jsonData,
          }
      
          axios(config)
            .then(function (response) {
              if (response.status == 200) {
                comp.fetchList()
                comp.setState({
                    agentId: [],
                    stateName: []
                })
                alert("State Assignedd Successfully!!")
              }
            })
            .catch(function (error) {
              console.log(error)
              comp.handleNon200Response(error, "3")
            })

        // firebase.firestore().collection("Agents").doc(data.agentId.value).update({
        //     State: st
        // })
        // .then(function(ref) {
        //     alert("State Assignedd Successfully!!")

        // })
    }


handleAgent = (agentId) => {
    this.setState({ 
      agentId,
    });
  };

  stateOnChnage = stateName => {
    this.setState({ stateName });
  }


  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })

    let agSelect = []
    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(newPage) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/approvedAgents/pagination',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
      console.log("Response", response.data)
      if(response.status == 200) {
        for(let i=0; i< response.data.agentsList.length; i++) {
        if(response.data.agentsList[i].agentid !== "") {
            const dict = {
              label: (response.data.agentsList[i].agentid) + "-" + response.data.agentsList[i].name,
              value: response.data.agentsList[i]._id
            }
            agSelect.push(dict)
          }
        }
          comp.setState({
            firebaseData: response.data.agentsList,
            totalRecords: response.data.totalRecords,
            agents: agSelect
        })
    }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "98");
    });
  }
  
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })

    let agSelect = []
    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = parseInt(event.target.value, 10)
    jsonData.pageCount = Number(0) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/approvedAgents/pagination',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
      if(response.status == 200) {
        for(let i=0; i< response.data.agentsList.length; i++) {
        if(response.data.agentsList[i].agentid !== "") {
            const dict = {
              label: (response.data.agentsList[i].agentid) + "-" + response.data.agentsList[i].name,
              value: response.data.agentsList[i]._id
            }
            agSelect.push(dict)
          }
        }
          comp.setState({
            firebaseData: response.data.agentsList,
            totalRecords: response.data.totalRecords,
            agents: agSelect
        })
    }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "99");
    });
  }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Masters" breadcrumbItem="Agent Assign State" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                            <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                            <Label htmlFor="validationCustom03">Agent<span style = {{color: "red"}}>*</span></Label>
                                                            <Select
                                                                // isMulti={true}
                                                                onChange={this.handleAgent}
                                                                options={this.state.agents}
                                                                id="agentId"
                                                                value = {this.state.agentId}
                                                                name = "agentId"
                                                                placeholder = "Select Agent"
                                                                menuPortalTarget={document.body}
                                                                menuPosition={'fixed'} 
                                                            />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">State<span style = {{color: "red"}}>*</span></Label>
                                                        <Select
                                                            value = {this.state.stateName}
                                                            isMulti={false}
                                                            onChange={this.stateOnChnage}
                                                            options={this.state.states}
                                                            classNamePrefix="select1-selection"
                                                            id = "stateName"
                                                            placeholder = "Select States"
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <br />

                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Add</Button>

                                            <br /> <br />

                                        <Row>

                                            <Col md = "4">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            {/* <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData, "User Report")} type="submit">Excel</Button> */}
                                            {/* <ReactHTMLTableToExcel  
                                                className="btn btn-primary"  
                                                table="dataTableExcel"  
                                                filename="Delivery Agent Report"  
                                                sheet="Sheet"  
                                                buttonText="Excel" />   */}
                                            </Col>
                                        </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Agent Id</th>
                                                            <th>Name</th>
                                                            <th>Number</th>
                                                            <th>State</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.agentid}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.number}</td>
                                                        <td>{rowData.state}</td>
                                                        </tr>
                                                    ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentAssignState;
