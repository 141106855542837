import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class BillingReport extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleFilter,
    };
    this.state = {
      firebaseData: [],
      firebaseData1: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      agents: [],
      products: [],
      agent: '',
      product: '',
      moveToView: false,
      startDate: '',
      endDate: '',
      total: '0',

      pages: [30, 50, 100, 300, 1000],
      page: 0,
      rowsPerPage: 30,
      lastVisible: {},
      count: 0
    };
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })
  }
  
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  componentDidMount() {
    let comp = this
    let count = 0

    var today = new Date()
    var currentDate = formatDate(today)   

    // firebase.firestore().collection("Payments")
    // .orderBy("CreatedAt", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     var totalAmount = 0
    //     querySnapshot.forEach(function(doc) {
    //       if(typeof doc.data().Date == "string") {
    //         if(formatDate(doc.data().Date) === currentDate) {
    //           if(doc.data().Amount !== undefined) {
    //             count = count + 1
    //           }
    //         }
    //       }
    //     });
    //     comp.setState({
    //       count: count,
    //     })
    // });

    // firebase.firestore().collection("Payments")
    // .orderBy("CreatedAt", "desc")
    // .limit(this.state.rowsPerPage)
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     var totalAmount = 0

    //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
    //     console.log("last", lastVisible);

    //     querySnapshot.forEach(function(doc) {
    //       if(typeof doc.data().Date == "string") {
    //         if(formatDate(doc.data().Date) === currentDate) {
    //           if(doc.data().Amount !== undefined) {
    //             totalAmount = parseFloat(parseFloat(totalAmount) + parseFloat(doc.data().Amount)).toFixed(2)
    //             loans.push(doc.data())
    //           }
    //         }
    //       }
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //       total: totalAmount,
    //       lastVisible: lastVisible
    //     })
    // });

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/agents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
          comp.setState({
            agents: response.data,
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    // firebase.firestore().collection("Payments")
    // .orderBy("CreatedAt", "desc")
    // .get().then((querySnapshot) => {
    //   var loans = [];
    //   querySnapshot.forEach(function(doc) {
    //     if(typeof doc.data().Date == "string") {
    //         if(doc.data().Amount !== undefined) {
    //             const loc = {
    //               Name: doc.data().Name,
    //               MobileNumber: doc.data().MobileNumber,
    //               Amount: doc.data().Amount,
    //               Email: doc.data().Email,
    //               Bank: doc.data().Bank,
    //               CaseType: doc.data().CaseType,
    //               GeoLimit: doc.data().GeoLimit,
    //               Date: doc.data().Date
    //             }
    //             loans.push(loc)
    //         }
    //       }
    //   });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });
    comp.fetchList()
  }

  async fetchList() {
      let comp = this

      var loans = []
      var loans1 = []
      var totalAmount = 0
  
      var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/payment',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
      };
      
      axios(config)
      .then(function (response) {
          console.log(response.data)
          if(response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
              if(response.data[i].amount !== undefined && response.data[i].amount !== "") {
                totalAmount = parseFloat(parseFloat(totalAmount) + parseFloat(response.data[i].amount)).toFixed(2)
                loans.push(response.data[i])

                const loc = {
                  Name: response.data[i].name,
                  MobileNumber: response.data[i].mobilenumber,
                  Amount: response.data[i].amount,
                  Email: response.data[i].email,
                  Bank: response.data[i].bank,
                  CaseType: response.data[i].casetype,
                  GeoLimit: response.data[i].geolimit,
                  Date: response.data[i].date.slice(0, 10)
                }
                loans1.push(loc)
              }
            }
            comp.setState({
              firebaseData: loans,
              firebaseData1: loans1,
              total: totalAmount,
            })
          }
      })
      .catch(function (error) {
        console.log(error);
        // comp.handleNon200Response(error, "1");
      });
  }

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

handleFilter(e) {
  e.preventDefault();

  let data = this.state

  let comp = this

  var loans = []
  var totalAmount = 0

  if(data.startDate === '') {
    alert("Select Start date");
    document.getElementById("startDate").focus();
    return;
  }

  if(data.endDate === '') {
    alert("Select End Date");
    document.getElementById("endDate").focus();
    return;
  }

  let js1 = {
    startDate: data.startDate,
    endDate: data.endDate
  }

  if(data.agent !== "") {
    js1.agent = data.agent
  }

  var jsonData = JSON.stringify(js1);


  var config = {
    method: 'post',
    url: API_BASE_URL + '/auth/report/payment',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json',
    },
    data: jsonData
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
        for(let i=0; i< response.data.length; i++) {
          if(response.data[i].amount !== undefined && response.data[i].amount !== "") {
            totalAmount = parseFloat(parseFloat(totalAmount) + parseFloat(response.data[i].amount)).toFixed(2)
            loans.push(response.data[i])
          }
        }
        comp.setState({
          firebaseData: loans,
          total: totalAmount,
        })
      }
  })
  .catch(function (error) {
    console.log(error);
    comp.handleNon200Response(error, "1");
  });

//   if(data.agent === '') {
//     firebase.firestore().collection("Payments")
//     .orderBy("CreatedAt", "desc")
//     .get().then((querySnapshot) => {
//         var loans = [];
//         var totalAmount = 0
//         querySnapshot.forEach(function(doc) {
//           if(typeof doc.data().Date == "string") {
//             if((formatDate(doc.data().Date) >= data.startDate) && (formatDate(doc.data().Date) <= data.endDate)) {
//                 totalAmount = parseFloat(parseFloat(totalAmount) + parseFloat(doc.data().Amount)).toFixed(2)
//                 loans.push(doc.data())
//             }
//           }
//         });
//         comp.setState({
//           firebaseData: loans,
//           total: totalAmount
//         })
//     });
// }
// else {
//     firebase.firestore().collection("Payments")
//     .orderBy("CreatedAt", "desc")
//     .where("UserId", "==", data.agent)
//     .get().then((querySnapshot) => {
//         var loans = [];
//         var totalAmount = 0
//         querySnapshot.forEach(function(doc) {
//           if(typeof doc.data().Date == "string") {
//             if((formatDate(doc.data().Date) >= data.startDate) && (formatDate(doc.data().Date) <= data.endDate)) {
//                 totalAmount = parseFloat(parseFloat(totalAmount) + parseFloat(doc.data().Amount)).toFixed(2)
//                 loans.push(doc.data())
//             }
//           }
//         });
//         comp.setState({
//           firebaseData: loans,
//           total: totalAmount
//         })
//     });
//     }
}

// handleAssignAgent(key) {
//   let data = this.state
//   if(data.agentId.length === 0) {
//       alert("Select Agent");
//       return;
//   }

//   let name = data.agentId.label.split("-")

//     firebase.firestore().collection("LoanApplication").doc(key.ApplicationId).update({
//       AssignedAgentName: name[0],
//       AssignedAgentId: data.agentId.value
//     })
//         alert("Agent Assigned Successfully!!")

//         this.setState({
//           agentId: []
//         })

//         document.getElementById(`agentId${key.ApplicationId}`).value = ""
  
// }

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    td8 = tr[i].getElementsByTagName("td")[8];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
      txtValue8 = td8.textContent || td8.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7+ txtValue8;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  // viewInfo(key) {
  //   let comp = this

  //   sessionStorage.setItem("caseId", key.PushId)
  //   sessionStorage.setItem("fromComp", "hpCase")

  //   comp.setState({
  //     moveToView: true
  //   })
  // }

  exportToCSV(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Report" breadcrumbItem="Billing Report" />
            {this.state.moveToView ? <Redirect to = "view-case" /> : null}
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "7"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData1, "Billing Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />

                                        <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Agents<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.agent} id = "agent" name = "agent" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Agents</option>
                                                          {this.state.agents.map((rowData, index) => (
                                                            <option value = {rowData._id}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col> */}

                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilter.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                              <br />

                                              <Row>
                                                    <Col md = "4">
                                                        <h4>Total: {this.state.total}</h4>
                                                    </Col>
                                                </Row>
                                                <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email</th>
                                                            <th>Amount</th>
                                                            <th>Bank</th>
                                                            <th>Case Type</th>
                                                            <th>Geo Limit</th>
                                                            <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.mobilenumber}</td>
                                                        <td>{rowData.email}</td>
                                                        <td>{rowData.amount}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.casetype}</td>
                                                        <td>{rowData.geolimit}</td>
                                                        <td>{rowData.date.slice(0, 10)}</td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.firebaseData.length}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default BillingReport;