import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Select from 'react-select';
import Loader from "react-loader-spinner";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

const customStyles1 = {
  control: base => ({
    ...base,
    height: 53,
    minHeight: 35,
    backgroundColor: "white",
    overflow: "hidden",
    width: 250
  })
};


class CreateCase extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.submit = this.handleSubmit.bind(this);
    this.agentsOnChangeBV = this.agentsOnChangeBV.bind(this);
    this.agentsOnChangeRV = this.agentsOnChangeRV.bind(this);
    this.agentsOnChangePV = this.agentsOnChangePV.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.submit,
        '3': this.agentsOnChangeBV,
        '4': this.agentsOnChangeRV,
        '5': this.agentsOnChangePV
    };
    this.state = {
      firestoreData: [],
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',

      fi: '',
      productName: '',
      residenceAddress: '',
      resLat: '',
      resLong: '',
      businessAddress: '',
      busLat: '',
      busLong: '',
      permanentAddress: '',
      perLat: '',
      perLong: '',
      fiDate: '',
      fiFlag: '',
      fiTime: '',
      dob: '',
      agentName: '',
      menu: 'Select',

      bank: '',
      product: '',
      fiType: 'Select',
      applicationId: '',
      customerName: '',
      loanAmount: '',
      vehicle: '',
      coApplicantName: '',
      guranteeName: '',
      agent: [],
      agentName: '',
      caseType: '',
      geoLimit: 'Select',
      tatTime: '00:00',
      remarks: '',
      fiTypeBV: false,
      fiTypeRV: false,
      fiTypePV: false,
      singleAgent: false,
      hp: false,
      residenceAddres: '',

      rvAgent: [],
      rvAgentName: '',
      pvAgent: [],
      pvAgentName: '',
      bvAgent: [],
      bvAgentName: '',
      mobileNumber: '',

      stateName: [],
      states: [],

      banks: [],
      agents: [],
      products: [],
      applicationIds: [],
      load: true,

      mapPosition: {
        lat: "12.8452",
        lng: "77.66029"
       },
       markerPosition: {
        lat: "12.8452",
        lng: "77.66029"
        },
        latitude: 0,
        longitude: 0,
       stateValue: '', 
       branchName: '',
      //  userIdName: '',
    };
  }

  componentDidMount() {
    let comp = this

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    // const userIdName = window.sessionStorage.getItem('name');
    // if(userIdName){
    //   this.setState({ userIdName });
    // }

    // console.log("fjdcn", userIdName);

    comp.setState({
      stateValue: state,
    })

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        banks: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/loanproduct',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        products: response1.data
      })
    })
    .catch(function (error1) {
      console.log(error1);
      // comp.handleNon200Response(error1, "1");
    });

    var config2 = {
      method: 'get',
      url: API_BASE_URL + '/auth/approvedAgents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    let agSelect = []
    
    axios(config2)
    .then(function (response2) {
      if(response2.status == 200) {
        for(let i=0; i< response2.data.length; i++) {
          if(response2.data[i].agentid !== "") {
            const dict = {
              label: (response2.data[i].agentid) + "-" + response2.data[i].name,
              value: response2.data[i]._id
            }
            agSelect.push(dict)
          }
        }
          comp.setState({
            agents: agSelect
        })
      }
    })
    .catch(function (error2) {
      console.log(error2);
      // comp.handleNon200Response(error2, "1");
    });

    var config3 = {
      method: 'get',
      url: API_BASE_URL + '/auth/state',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    let states = []
    
    axios(config3)
    .then(function (response3) {
      for(let i=0; i< response3.data.length; i++) {
        if(response3.data[i].name !== "") {
          const dict = {
            label: response3.data[i].name,
            value: response3.data[i].name
          }
          states.push(dict)
        }
      }
      comp.setState({
        states: states,
      })
    })
    .catch(function (error3) {
      console.log(error3);
      // comp.handleNon200Response(error3, "1");
    });

    setTimeout(function() {
      comp.fetchList();
    }, 2000)
  }

  async fetchList() {
    let comp = this

    // var config = {
    //   method: 'get',
    //   url: API_BASE_URL + '/auth/case',
    //   headers: { 
    //     apikey: API_KEY, 
    //     Authorization: "Bearer " + getCookie("at"),
    //   }
    // };

    // let applicationIds = []
    
    // axios(config)
    // .then(function (response) {
    //   for(let i=0; i < response.data.length; i++) {
    //     if(response.data[i].applicationid !== "") {
    //         applicationIds.push(response.data[i].applicationid)
    //     } 
    //   }
    //   comp.setState({
    //     applicationIds: applicationIds,
    //   })
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   // comp.handleNon200Response(error, "1");
    // });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    }
  }

  defaultState() {
    this.setState({
      fi: '',
      residenceAddress: '',
      resLat: '',
      resLong: '',
      businessAddress: '',
      busLat: '',
      busLong: '',
      permanentAddress: '',
      perLat: '',
      perLong: '',
      fiDate: '',
      fiFlag: '',
      fiTime: '',
      dob: '',
      bank: '',
      product: '',
      fiType: 'Select',
      applicationId: '',
      customerName: '',
      loanAmount: '',
      mobileNumber: '',
      vehicle: '',
      coApplicantName: '',
      guranteeName: '',
      agent: '',
      geoLimit: 'Select',
      tatTime: '00:00',
      remarks: '',
      fiTypeBV: false,
      fiTypeRV: false,
      fiTypePV: false,
      singleAgent: false,
      hp: false,
      rvAgent: '',
      rvAgentName: '',
      pvAgent: '',
      pvAgentName: '',
      bvAgent: '',
      bvAgentName: '',
      stateName: [],
      branchName: '',
    })
  }

  async handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    let error = false


    if(data.bank === '') {
        alert("Select Bank");
        document.getElementById("bank").focus();
        return;
    }

    if(data.product === '') {
      alert("Select Product");
      document.getElementById("product").focus();
      return;
    }

    if(data.fiTypeBV == false && data.fiTypePV == false && data.fiTypeRV == false) {
      alert("Select FI Type");
      return;
    }

    if(data.applicationId === '') {
        alert("Enter Reference No");
        document.getElementById("applicationId").focus();
        return;
    }

    // if(data.applicationIds.indexOf(data.applicationId.trim()) !== -1) {
    //   alert("Application Id already exists")
    //   return
    // }

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }
    

    let jsonDataApplicationId = {
      applicationId: data.applicationId,
      state: state.split(",").slice(0, -1),
    }
  
    let jsonDataApplicationId1 = JSON.stringify(jsonDataApplicationId);
  
    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/case/searchByApplicationId',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonDataApplicationId1
    };
    
    await axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
          console.log("response.data", response.data)
          if(response.data.length > 0) {
            alert("Application Id already exists")
            error = true
            return
          }
        }
    })

    if(data.customerName === '') {
        alert("Enter Applicant Name");
        document.getElementById("customerName").focus();
        return;
    }

    if(data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    // if(data.loanAmount === '') {
    //   alert("Enter Amount");
    //   document.getElementById("loanAmount").focus();
    //   return;
    // }


    // if(data.agent === '') {
    //   alert("Select Agent");
    //   document.getElementById("agent").focus();
    //   return;
    // }

    if(data.geoLimit === 'Select') {
      alert("Select GEO Limit");
      document.getElementById("geoLimit").focus();
      return;
    }

    console.log(data.stateValue)

    if(data.stateValue.includes("SUPER ADMIN")) {
      if(data.stateName.length === 0) {
        alert("Select States");
        document.getElementById("stateName").focus();
        return;
      }
    }

    let errorFlag = false;

    var st = []

    if(data.stateValue.includes("SUPER ADMIN")) {
        st.push("SUPER ADMIN")

        for(let i=0; i< data.stateName.length; i++) {
          st.push(data.stateName[i].value)
        }
    }

    else {
      st.push("SUPER ADMIN")
      let stat = data.stateValue.split(",").slice(0, -1)
        for(let i=0; i< stat.length; i++) {
          st.push(stat[i])
        }
    }

    console.log("St", st)

    var today = new Date()

    var hp = ""
    if(data.hp == true) {
      hp = "YES"
    } else {
      hp = "NO"
    }

    if(!error) {
      if(data.fiTypeRV == true) {

        let agent = ""
        let agentName = ""
        let agentId = ""
  
      if(data.singleAgent === true) {
  
        if(data.agent.length !== 0) {
            agent = data.agent.value
            agentName = data.agent.label.split("-")[1]
            agentId = data.agent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      } else {
  
        if(data.rvAgent.length !== 0) {
            agent = data.rvAgent.value
            agentName = data.rvAgent.label.split("-")[1]
            agentId = data.rvAgent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      }
  
      console.log("Agent", agent)
      console.log("Agemt Name", agentName)
      console.log("Agent Id", agentId)
  
      const userIdName = sessionStorage.getItem('name');
  
        var jsonData1 = JSON.stringify({
          "bank": data.bank,
          "productname": data.product,
          "applicationid": data.applicationId.trim(),
          "customername": data.customerName,
          "loanamount": data.loanAmount,
          "mobilenumber": data.mobileNumber,
          "hp": hp,
          "vehicle": data.vehicle,
          "coapplicantname": data.coApplicantName,
          "guranteename": data.guranteeName,
          "agentname": agentName,
          "agent": agent,
          "agentid": agentId,
          "geolimit": data.geoLimit,
          "tattime": data.tatTime,
          "remarks": data.remarks,
          "fi": "RV",
          "residenceaddress": data.residenceAddress,
          "location": data.resLat + ","+ data.resLong,
          "fidate": "",
          "fitime": "",
          "fiflag": "",
          "dateofbirth": "",
          "branchName": data.branchName,
          "timestamp": String(new Date()),
          "status": "Pending",
          "paid": "No",
          "state": st,
          "pushid": Date.now(),
          "caseCreatedBy": userIdName,
          ...(agentId !== undefined && { "caseAssignedTime": new Date() }),
        });
  
        var config1 = {
          method: 'post',
          url: API_BASE_URL + "/auth/case",
          headers: { 
            'apikey': API_KEY, 
            'Authorization': 'Bearer ' + getCookie('at'),
            'Content-Type': 'application/json'
          },
          data : jsonData1
        };
        
        axios(config1)
        .then(function (response1) {
          if(response1.status == 201) {
            // comp.defaultState()
            // comp.fetchList()
            // alert("Added Successfully")
          }
        })
        .catch(function (error) {
          errorFlag = true
          console.log(error.response.data)
          comp.handleNon200Response(error, "2")
        });   
      }
  
      if(data.fiTypeBV == true) {
  
  
        let agent = ""
        let agentName = ""
        let agentId = ""
  
      if(data.singleAgent === true) {
  
        if(data.agent.length !== 0) {
            agent = data.agent.value
            agentName = data.agent.label.split("-")[1]
            agentId = data.agent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      } else {
  
        if(data.bvAgent.length !== 0) {
            agent = data.bvAgent.value
            agentName = data.bvAgent.label.split("-")[1]
            agentId = data.bvAgent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      }
  
      console.log("Agent", agent)
      console.log("Agemt Name", agentName)
      console.log("Agent Id", agentId)

      const userIdName = sessionStorage.getItem('name');
  
      var jsonData2 = JSON.stringify({
        "bank": data.bank,
        "productname": data.product,
        "hp": hp,
        "applicationid": data.applicationId.trim(),
        "customername": data.customerName,
        "loanamount": data.loanAmount,
        "mobilenumber": data.mobileNumber,
        "vehicle": data.vehicle,
        "coapplicantname": data.coApplicantName,
        "guranteename": data.guranteeName,
        "agentname": agentName,
        "agent": agent,
        "agentid": agentId,
        "geolimit": data.geoLimit,
        "tattime": data.tatTime,
        "remarks": data.remarks,
        "fi": "BV",
        "officeaddress": data.businessAddress,
        "location": data.busLat + ","+ data.busLong,
        "fidate": "",
        "fitime": "",
        "fiflag": "",
        "dateofbirth": "",
        "branchName": data.branchName,
        "timestamp": String(new Date()),
        "status": "Pending",
        "paid": "No",
        "state": st,
        "pushid": Date.now(),
        "caseCreatedBy": userIdName,
        ...(agentId !== undefined && { "caseAssignedTime": new Date() }),
      });
  
      var config2 = {
        method: 'post',
        url: API_BASE_URL + "/auth/case",
        headers: { 
          'apikey': API_KEY, 
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data : jsonData2
      };
      
      axios(config2)
      .then(function (response2) {
        if(response2.status == 201) {
          // comp.defaultState()
          // comp.fetchList()
          // alert("Added Successfully")
        }
      })
      .catch(function (error2) {
        errorFlag = true
        console.log(error2.response.data)
        comp.handleNon200Response(error2, "2")
      });   
      }
  
      if(data.fiTypePV == true) {
  
        let agent = ""
        let agentName = ""
        let agentId = ""
  
      if(data.singleAgent === true) {
  
        if(data.agent.length !== 0) {
            agent = data.agent.value
            agentName = data.agent.label.split("-")[1]
            agentId = data.agent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      } else {
  
        if(data.pvAgent.length !== 0) {
            agent = data.pvAgent.value
            agentName = data.pvAgent.label.split("-")[1]
            agentId = data.pvAgent.label.split("-")[0]
        } else {
          agent = ""
          agentName = ""
          agentId = ""
        }
      }
  
      console.log("Agent", agent)
      console.log("Agemt Name", agentName)
      console.log("Agent Id", agentId)

      const userIdName = sessionStorage.getItem('name');
  
      var jsonData3 = JSON.stringify({
        "bank": data.bank,
        "productname": data.product,
        "applicationid": data.applicationId.trim(),
        "customername": data.customerName,
        "loanamount": data.loanAmount,
        "mobilenumber": data.mobileNumber,
        "hp": hp,
        "vehicle": data.vehicle,
        "coapplicantname": data.coApplicantName,
        "guranteename": data.guranteeName,
        "agentname": agentName,
        "agent": agent,
        "agentid": agentId,
        "geolimit": data.geoLimit,
        "tattime": data.tatTime,
        "remarks": data.remarks,
        "fi": "PV",
        "permanentaddress": data.permanentAddress,
        "location": data.perLat + ","+ data.perLong,
        "fidate": "",
        "fitime": "",
        "fiflag": "",
        "dateofbirth": "",
        "branchName": data.branchName,
        "timestamp": String(new Date()),
        "status": "Pending",
        "paid": "No",
        "state": st,
        "pushid": Date.now(),
        "caseCreatedBy": userIdName,
        ...(agentId !== undefined && { "caseAssignedTime": new Date() }),
      });
  
      var config3 = {
        method: 'post',
        url: API_BASE_URL + "/auth/case",
        headers: { 
          'apikey': API_KEY, 
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data : jsonData3
      };
      
      axios(config3)
      .then(function (response3) {
        if(response3.status == 201) {
          // comp.defaultState()
          // comp.fetchList()
          // alert("Added Successfully")
        }
      })
      .catch(function (error3) {
        errorFlag = true
        console.log(error3.response.data)
        comp.handleNon200Response(error3, "2")
      }); 
    }
  
    setTimeout(function() {
      if(!errorFlag) {
        comp.defaultState();
        comp.fetchList();
        
        document.getElementById("create-form").reset()
        alert("Case Added Successfully")
      }
    }, 1500)
    }
} 

agentsOnChange(e) {
  const value = e.target.value
  let comp = this

  firebase.firestore().collection("Agents").doc(value)
  .onSnapshot(function(querySnapshot) {
    comp.setState({
      agentName: querySnapshot.data().Name,
      agent: value
    })
  })
}

agentsOnChangeBV(e) {
  const value = e.target.value
  let comp = this

  var config = {
    method: "get",
    url: API_BASE_URL + "/auth/agents/" + value,
    headers: {
      apikey: API_KEY,
      Authorization: "Bearer " + getCookie("at"),
      "Content-Type": "application/json",
    },
  }

  axios(config)
    .then(function (response) {
      if (response.status == 200) {
        comp.setState({
          bvAgentName: response.data.name,
          bvAgent: value
        })
      }
    })
    .catch(function (error) {
      console.log(error)
      comp.handleNon200Response(error, "3")
    })
}

agentsOnChangeRV(e) {
  const value = e.target.value
  let comp = this

  var config = {
    method: "get",
    url: API_BASE_URL + "/auth/agents/" + value,
    headers: {
      apikey: API_KEY,
      Authorization: "Bearer " + getCookie("at"),
      "Content-Type": "application/json",
    },
  }

  axios(config)
    .then(function (response) {
      if (response.status == 200) {
        comp.setState({
          rvAgentName: response.data.name,
          rvAgent: value
        })
      }
    })
    .catch(function (error) {
      console.log(error)
      comp.handleNon200Response(error, "4")
    })
}

agentsOnChangePV(e) {
  const value = e.target.value
  let comp = this

  var config = {
    method: "get",
    url: API_BASE_URL + "/auth/agents/" + value,
    headers: {
      apikey: API_KEY,
      Authorization: "Bearer " + getCookie("at"),
      "Content-Type": "application/json",
    },
  }

  axios(config)
    .then(function (response) {
      if (response.status == 200) {
        comp.setState({
          pvAgentName: response.data.name,
          pvAgent: value
        })
      }
    })
    .catch(function (error) {
      console.log(error)
      comp.handleNon200Response(error, "5")
    })
}

handleCityChange1 = (address) => {
  this.setState({ 
      residenceAddress: address
   })
}

handleCityChange2 = (address) => {
  this.setState({ 
      businessAddress: address
   })
}

handleCityChange3 = (address) => {
  this.setState({ 
      permanentAddress: address
   })
}

handleSelect1 = (address) => {
  let comp = this
  geocodeByAddress(address)
    .then(function(results){
        var add = results[0].formatted_address
      comp.setState({
          residenceAddress: add
      })
    })
    geocodeByAddress(address)
    .then(results1 => getLatLng(results1[0]))
    .then(({ lat, lng }) => {
      // console.log('Successfully got latitude and longitude', { lat, lng })
      comp.setState({
          resLat: lat,
          resLong: lng
      })
    }
      
    )
    .catch(error => console.error('Error', error))
}

handleSelect2 = (address) => {
  let comp = this
  geocodeByAddress(address)
    .then(function(results){
        var add = results[0].formatted_address
      comp.setState({
          businessAddress: add
      })
    })
    geocodeByAddress(address)
    .then(results1 => getLatLng(results1[0]))
    .then(({ lat, lng }) => {
      // console.log('Successfully got latitude and longitude', { lat, lng })
      comp.setState({
          busLat: lat,
          busLong: lng
      })
    }
      
    )
    .catch(error => console.error('Error', error))
}

handleSelect3 = (address) => {
  let comp = this
  geocodeByAddress(address)
    .then(function(results){
        var add = results[0].formatted_address
      comp.setState({
          permanentAddress: add
      })
    })
    geocodeByAddress(address)
    .then(results1 => getLatLng(results1[0]))
    .then(({ lat, lng }) => {
      // console.log('Successfully got latitude and longitude', { lat, lng })
      comp.setState({
          perLat: lat,
          perLong: lng
      })
    }
      
    )
    .catch(error => console.error('Error', error))
}

handleAgent = (agent) => {
  this.setState({ 
    agent,
  });
};

handleAgent1 = (rvAgent) => {
  this.setState({ 
    rvAgent
  });
};

handleAgent2 = (bvAgent) => {
  this.setState({ 
    bvAgent
  });
};

handleAgent3 = (pvAgent) => {
  this.setState({ 
    pvAgent
  });
};

handleState = (stateName) => {
  this.setState({ 
    stateName
  });
};

  render() {
    const renderInput1 = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
      <div className="autocomplete-root">
          <input className="form-control" {...getInputProps()} />
          <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                  const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                  const style = suggestion.active
                  ? { backgroundColor: '#F8F8FF', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                      <div {...getSuggestionItemProps(suggestion, {className, style})}>
                  <span>{suggestion.description}</span>
                  </div>
                  )
              })}
          </div>
      </div>
  );

  const renderInput2 = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <div className="autocomplete-root">
        <input className="form-control" {...getInputProps()} />
        <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => {
                const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
                const style = suggestion.active
                ? { backgroundColor: '#F8F8FF', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                    <div {...getSuggestionItemProps(suggestion, {className, style})}>
                <span>{suggestion.description}</span>
                </div>
                )
            })}
        </div>
    </div>
);

const renderInput3 = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
  <div className="autocomplete-root">
      <input className="form-control" {...getInputProps()} />
      <div className="autocomplete-dropdown-container">
          {suggestions.map(suggestion => {
              const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
              const style = suggestion.active
              ? { backgroundColor: '#F8F8FF', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                  <div {...getSuggestionItemProps(suggestion, {className, style})}>
              <span>{suggestion.description}</span>
              </div>
              )
          })}
      </div>
  </div>
);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cases" breadcrumbItem="Create Case" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                    {/* {this.state.applicationIds.length == 0 ? 
                                    <Loader 
                                      type="Circles" 
                                      color="#4058b0" 
                                      height={130} 
                                      width={130}
                                      timeout={10000}
                                      style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2}}
                                    /> : null } */}
                                      <Form id = "create-form">
                                            <Row>
                                              <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Branch Name</Label>
                                                        <Input
                                                          name="branchName"
                                                          placeholder="Branch Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="branchName"
                                                          value = {this.state.branchName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Products<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.product} id = "product" name = "product" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Product</option>
                                                          {this.state.products.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">FI Type<span style = {{color: "red"}}>*</span></Label>
                                                        <Row>
                                                          <Col md = "4">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="fiTypeBV" onChange={() => false} checked={this.state.fiTypeBV} />
                                                              <label className="custom-control-label" onClick={() => { this.setState({ fiTypeBV: !this.state.fiTypeBV }) }} >BV </label>
                                                            </div>
                                                          </Col>

                                                          <Col md = "4">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="fiTypeRV" onChange={() => false} checked={this.state.fiTypeRV} />
                                                              <label className="custom-control-label" onClick={() => { this.setState({ fiTypeRV: !this.state.fiTypeRV }) }} >RV </label>
                                                            </div>
                                                          </Col>

                                                          <Col md = "4">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="fiTypePV" onChange={() => false} checked={this.state.fiTypePV} />
                                                              <label className="custom-control-label" onClick={() => { this.setState({ fiTypePV: !this.state.fiTypePV }) }} >PV </label>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Reference Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="applicationId"
                                                          placeholder="Reference Number"
                                                          type="text"
                                                          className="form-control"
                                                          id="applicationId"
                                                          value = {this.state.applicationId}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Applicant Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="customerName"
                                                          placeholder="Applicant Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="customerName"
                                                          value = {this.state.customerName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="text"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          value = {this.state.mobileNumber}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                              </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Amount</Label>
                                                        <Input
                                                          name="loanAmount"
                                                          placeholder="Amount"
                                                          type="number"
                                                          className="form-control"
                                                          id="loanAmount"
                                                          value = {this.state.loanAmount}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                {this.state.fiTypeRV === true ?
                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Residence Address</Label>
                                                        <PlacesAutocomplete
                                                            value={this.state.residenceAddress}
                                                            onChange={this.handleCityChange1}
                                                            onSelect={this.handleSelect1}
                                                            id = "residenceAddress"
                                                            placeholder = "Residence Address"
                                                          >
                                                            {renderInput1}
                                                        </PlacesAutocomplete> 
                                                    </FormGroup>
                                                </Col>
                                              </Row> : null }

                                              {this.state.fiTypeBV === true ?
                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Business Address</Label>
                                                        <PlacesAutocomplete
                                                            value={this.state.businessAddress}
                                                            onChange={this.handleCityChange2}
                                                            onSelect={this.handleSelect2}
                                                            id = "businessAddresss"
                                                            placeholder = "Business Address"
                                                          >
                                                            {renderInput2}
                                                        </PlacesAutocomplete> 
                                                    </FormGroup>
                                                </Col>
                                              </Row> : null }

                                              {this.state.fiTypePV === true ?
                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Permanent Address</Label>
                                                        <PlacesAutocomplete
                                                            value={this.state.permanentAddress}
                                                            onChange={this.handleCityChange3}
                                                            onSelect={this.handleSelect3}
                                                            id = "permanentAddresss"
                                                            placeholder = "Permanent Address"
                                                          >
                                                            {renderInput3}
                                                        </PlacesAutocomplete> 
                                                    </FormGroup>
                                                </Col>
                                              </Row> : null }

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Vehicle</Label>
                                                        <Input
                                                          name="vehicle"
                                                          placeholder="Vehicle"
                                                          type="text"
                                                          className="form-control"
                                                          id="vehicle"
                                                          value = {this.state.vehicle}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Co-Applicant Name</Label>
                                                        <Input
                                                          name="coApplicantName"
                                                          placeholder="Co-Applicant Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="coApplicantName"
                                                          value = {this.state.coApplicantName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Gaurantor Name</Label>
                                                        <Input
                                                          name="guranteeName"
                                                          placeholder="Gaurantor Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="guranteeName"
                                                          value = {this.state.guranteeName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                              </Row>

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">High Priority</Label>
                                                        <Row>
                                                          <Col md = "4">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="hp" onChange={() => false} checked={this.state.hp} />
                                                              <label className="custom-control-label" onClick={() => { this.setState({ hp: !this.state.hp }) }} >HP </label>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Single Agent</Label>
                                                        <Row>
                                                          <Col md = "4">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="singleAgent" onChange={() => false} checked={this.state.singleAgent} />
                                                              <label className="custom-control-label" onClick={() => { this.setState({ singleAgent: !this.state.singleAgent }) }} >Single Agent </label>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                {this.state.singleAgent === true ?
                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Agent</Label>
                                                          <Select
                                                                value = {this.state.agent}
                                                                onChange={this.handleAgent}
                                                                options={this.state.agents}
                                                                id= "agent"
                                                                placeholder = "Select Agent"
                                                                styles = {customStyles1}
                                                            />
                                                        {/* <Input type="select"  value = {this.state.agent} id = "agent" name = "agent" onChange = {this.agentsOnChange.bind(this)}>
                                                        <option value = "">Select Agent</option>
                                                          {this.state.agents.map((rowData, index) => (
                                                            <option value = {rowData.UserId}>{rowData.Name}</option>
                                                          ))}
                                                      </Input> */}
                                                    </FormGroup>
                                                </Col>
                                                </Row> : null }

                                                {this.state.fiTypeRV === true && this.state.singleAgent === false  ?
                                                   <Row>
                                                   <Col md = {{size: 6}}>
                                                       <FormGroup>
                                                           <Label htmlFor="validationCustom05">RV Agent</Label>
                                                           <Select
                                                                value = {this.state.rvAgent}
                                                                onChange={this.handleAgent1}
                                                                options={this.state.agents}
                                                                id= "rvAgent"
                                                                placeholder = "Select Agent"
                                                                styles = {customStyles1}
                                                            />
                                                           {/* <Input type="select"  value = {this.state.rvAgent} id = "rvAgent" name = "rvAgent" onChange = {this.agentsOnChangeRV.bind(this)}>
                                                           <option value = "">Select Agent</option>
                                                             {this.state.agents.map((rowData, index) => (
                                                               <option value = {rowData.UserId}>{rowData.Name}</option>
                                                             ))}
                                                         </Input> */}
                                                       </FormGroup>
                                                   </Col>
                                                   </Row> : null }

                                                   {this.state.fiTypeBV === true && this.state.singleAgent === false ?
                                                   <Row>
                                                   <Col md = {{size: 6}}>
                                                       <FormGroup>
                                                           <Label htmlFor="validationCustom05">BV Agent</Label>
                                                           <Select
                                                                value = {this.state.bvAgent}
                                                                onChange={this.handleAgent2}
                                                                options={this.state.agents}
                                                                id= "bvAgent"
                                                                placeholder = "Select Agent"
                                                                styles = {customStyles1}
                                                            />
                                                           {/* <Input type="select"  value = {this.state.bvAgent} id = "bvAgent" name = "bvAgent" onChange = {this.agentsOnChangeBV.bind(this)}>
                                                           <option value = "">Select Agent</option>
                                                             {this.state.agents.map((rowData, index) => (
                                                               <option value = {rowData.UserId}>{rowData.Name}</option>
                                                             ))}
                                                         </Input> */}
                                                       </FormGroup>
                                                   </Col>
                                                   </Row> : null }

                                                   {this.state.fiTypePV === true && this.state.singleAgent === false ?
                                                   <Row>
                                                   <Col md = {{size: 6}}>
                                                       <FormGroup>
                                                           <Label htmlFor="validationCustom05">PV Agent</Label>
                                                           <Select
                                                                value = {this.state.pvAgent}
                                                                onChange={this.handleAgent3}
                                                                options={this.state.agents}
                                                                id= "pvAgent"
                                                                placeholder = "Select Agent"
                                                                styles = {customStyles1}
                                                            />
                                                           {/* <Input type="select"  value = {this.state.pvAgent} id = "pvAgent" name = "pvAgent" onChange = {this.agentsOnChangePV.bind(this)}>
                                                           <option value = "">Select Agent</option>
                                                             {this.state.agents.map((rowData, index) => (
                                                               <option value = {rowData.UserId}>{rowData.Name}</option>
                                                             ))}
                                                         </Input> */}
                                                       </FormGroup>
                                                   </Col>
                                                   </Row> : null }

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Geo Limit<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.geoLimit} id = "geoLimit" name = "geoLimit" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select Geo Limit</option>
                                                        <option value = "Local">Local</option>
                                                        <option value = "Outstation1">Outstation1</option>
                                                        <option value = "Outstation2">Outstation2</option>
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">TAT Time</Label>
                                                        <Input type="select"  value = {this.state.tatTime} id = "tatTime" name = "tatTime" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "00:00">00:00</option>
                                                        <option value = "00:05">00:05</option>
                                                        <option value = "00:10">00:10</option>
                                                        <option value = "00:15">00:15</option>
                                                        <option value = "00:20">00:20</option>
                                                        <option value = "00:25">00:25</option>
                                                        <option value = "00:30">00:30</option>
                                                        <option value = "00:35">00:35</option>
                                                        <option value = "00:40">00:40</option>
                                                        <option value = "00:45">00:45</option>
                                                        <option value = "00:50">00:50</option>
                                                        <option value = "00:55">00:55</option>
                                                        <option value = "01:00">01:00</option>
                                                        <option value = "01:05">01:05</option>
                                                        <option value = "01:10">01:10</option>
                                                        <option value = "01:15">01:15</option>
                                                        <option value = "01:20">01:20</option>
                                                        <option value = "01:25">01:25</option>
                                                        <option value = "01:30">01:30</option>
                                                        <option value = "01:35">01:35</option>
                                                        <option value = "01:40">01:40</option>
                                                        <option value = "01:45">01:45</option>
                                                        <option value = "01:50">01:50</option>
                                                        <option value = "01:55">01:55</option>
                                                        <option value = "02:00">02:00</option>
                                                        <option value = "02:05">02:05</option>
                                                        <option value = "02:10">02:10</option>
                                                        <option value = "02:15">02:15</option>
                                                        <option value = "02:20">02:20</option>
                                                        <option value = "02:25">02:25</option>
                                                        <option value = "02:30">02:30</option>
                                                        <option value = "02:35">02:35</option>
                                                        <option value = "02:40">02:40</option>
                                                        <option value = "02:45">02:45</option>
                                                        <option value = "02:50">02:50</option>
                                                        <option value = "02:55">02:55</option>
                                                        <option value = "03:00">03:00</option>
                                                        <option value = "03:05">03:05</option>
                                                        <option value = "03:10">03:10</option>
                                                        <option value = "03:15">03:15</option>
                                                        <option value = "03:20">03:20</option>
                                                        <option value = "03:25">03:25</option>
                                                        <option value = "03:30">03:30</option>
                                                        <option value = "03:35">03:35</option>
                                                        <option value = "03:40">03:40</option>
                                                        <option value = "03:45">03:45</option>
                                                        <option value = "03:50">03:50</option>
                                                        <option value = "03:55">03:55</option>
                                                        <option value = "04:00">04:00</option>
                                                        <option value = "04:05">04:05</option>
                                                        <option value = "04:10">04:10</option>
                                                        <option value = "04:15">04:15</option>
                                                        <option value = "04:20">04:20</option>
                                                        <option value = "04:25">04:25</option>
                                                        <option value = "04:30">04:30</option>
                                                        <option value = "04:35">04:35</option>
                                                        <option value = "04:40">04:40</option>
                                                        <option value = "04:45">04:45</option>
                                                        <option value = "04:50">04:50</option>
                                                        <option value = "04:55">04:55</option>
                                                        <option value = "05:00">05:00</option>
                                                        <option value = "05:05">05:05</option>
                                                        <option value = "05:10">05:10</option>
                                                        <option value = "05:15">05:15</option>
                                                        <option value = "05:20">05:20</option>
                                                        <option value = "05:25">05:25</option>
                                                        <option value = "05:30">05:30</option>
                                                        <option value = "05:35">05:35</option>
                                                        <option value = "05:40">05:40</option>
                                                        <option value = "05:45">05:45</option>
                                                        <option value = "05:50">05:50</option>
                                                        <option value = "05:55">05:55</option>
                                                        <option value = "06:00">06:00</option>
                                                        <option value = "06:05">06:05</option>
                                                        <option value = "06:10">06:10</option>
                                                        <option value = "06:15">06:15</option>
                                                        <option value = "06:20">06:20</option>
                                                        <option value = "06:25">06:25</option>
                                                        <option value = "06:30">06:30</option>
                                                        <option value = "06:35">06:35</option>
                                                        <option value = "06:40">06:40</option>
                                                        <option value = "06:45">06:45</option>
                                                        <option value = "06:50">06:50</option>
                                                        <option value = "06:55">06:55</option>
                                                        <option value = "07:00">07:00</option>
                                                        <option value = "07:05">07:05</option>
                                                        <option value = "07:10">07:10</option>
                                                        <option value = "07:15">07:15</option>
                                                        <option value = "07:20">07:20</option>
                                                        <option value = "07:25">07:25</option>
                                                        <option value = "07:30">07:30</option>
                                                        <option value = "07:35">07:35</option>
                                                        <option value = "07:40">07:40</option>
                                                        <option value = "07:45">07:45</option>
                                                        <option value = "07:50">07:50</option>
                                                        <option value = "07:55">07:55</option>
                                                        <option value = "08:00">08:00</option>
                                                        <option value = "08:05">08:05</option>
                                                        <option value = "08:10">08:10</option>
                                                        <option value = "08:15">08:15</option>
                                                        <option value = "08:20">08:20</option>
                                                        <option value = "08:25">08:25</option>
                                                        <option value = "08:30">08:30</option>
                                                        <option value = "08:35">08:35</option>
                                                        <option value = "08:40">08:40</option>
                                                        <option value = "08:45">08:45</option>
                                                        <option value = "08:50">08:50</option>
                                                        <option value = "08:55">08:55</option>
                                                        <option value = "09:00">09:00</option>
                                                        <option value = "09:05">09:05</option>
                                                        <option value = "09:10">09:10</option>
                                                        <option value = "09:15">09:15</option>
                                                        <option value = "09:20">09:20</option>
                                                        <option value = "09:25">09:25</option>
                                                        <option value = "09:30">09:30</option>
                                                        <option value = "09:35">09:35</option>
                                                        <option value = "09:40">09:40</option>
                                                        <option value = "09:45">09:45</option>
                                                        <option value = "09:50">09:50</option>
                                                        <option value = "09:55">09:55</option>
                                                        <option value = "10:00">10:00</option>
                                                        <option value = "10:05">10:05</option>
                                                        <option value = "10:10">10:10</option>
                                                        <option value = "10:15">10:15</option>
                                                        <option value = "10:20">10:20</option>
                                                        <option value = "10:25">10:25</option>
                                                        <option value = "10:30">10:30</option>
                                                        <option value = "10:35">10:35</option>
                                                        <option value = "10:40">10:40</option>
                                                        <option value = "10:45">10:45</option>
                                                        <option value = "10:50">10:50</option>
                                                        <option value = "10:55">10:55</option>
                                                        <option value = "11:00">11:00</option>
                                                        <option value = "11:05">11:05</option>
                                                        <option value = "11:10">11:10</option>
                                                        <option value = "11:15">11:15</option>
                                                        <option value = "11:20">11:20</option>
                                                        <option value = "11:25">11:25</option>
                                                        <option value = "11:30">11:30</option>
                                                        <option value = "11:35">11:35</option>
                                                        <option value = "11:40">11:40</option>
                                                        <option value = "11:45">11:45</option>
                                                        <option value = "11:50">11:50</option>
                                                        <option value = "11:55">11:55</option>
                                                        <option value = "12:00">12:00</option>
                                                        <option value = "12:05">12:05</option>
                                                        <option value = "12:10">12:10</option>
                                                        <option value = "12:15">12:15</option>
                                                        <option value = "12:20">12:20</option>
                                                        <option value = "12:25">12:25</option>
                                                        <option value = "12:30">12:30</option>
                                                        <option value = "12:35">12:35</option>
                                                        <option value = "12:40">12:40</option>
                                                        <option value = "12:45">12:45</option>
                                                        <option value = "12:50">12:50</option>
                                                        <option value = "12:55">12:55</option>
                                                        <option value = "13:00">13:00</option>
                                                        <option value = "13:05">13:05</option>
                                                        <option value = "13:10">13:10</option>
                                                        <option value = "13:15">13:15</option>
                                                        <option value = "13:20">13:20</option>
                                                        <option value = "13:25">13:25</option>
                                                        <option value = "13:30">13:30</option>
                                                        <option value = "13:35">13:35</option>
                                                        <option value = "13:40">13:40</option>
                                                        <option value = "13:45">13:45</option>
                                                        <option value = "13:50">13:50</option>
                                                        <option value = "13:55">13:55</option>
                                                        <option value = "14:00">14:00</option>
                                                        <option value = "14:05">14:05</option>
                                                        <option value = "14:10">14:10</option>
                                                        <option value = "14:15">14:15</option>
                                                        <option value = "14:20">14:20</option>
                                                        <option value = "14:25">14:25</option>
                                                        <option value = "14:30">14:30</option>
                                                        <option value = "14:35">14:35</option>
                                                        <option value = "14:40">14:40</option>
                                                        <option value = "14:45">14:45</option>
                                                        <option value = "14:50">14:50</option>
                                                        <option value = "14:55">14:55</option>
                                                        <option value = "15:00">15:00</option>
                                                        <option value = "15:05">15:05</option>
                                                        <option value = "15:10">15:10</option>
                                                        <option value = "15:15">15:15</option>
                                                        <option value = "15:20">15:20</option>
                                                        <option value = "15:25">15:25</option>
                                                        <option value = "15:30">15:30</option>
                                                        <option value = "15:35">15:35</option>
                                                        <option value = "15:40">15:40</option>
                                                        <option value = "15:45">15:45</option>
                                                        <option value = "15:50">15:50</option>
                                                        <option value = "15:55">15:55</option>
                                                        <option value = "16:00">16:00</option>
                                                        <option value = "16:05">16:05</option>
                                                        <option value = "16:10">16:10</option>
                                                        <option value = "16:15">16:15</option>
                                                        <option value = "16:20">16:20</option>
                                                        <option value = "16:25">16:25</option>
                                                        <option value = "16:30">16:30</option>
                                                        <option value = "16:35">16:35</option>
                                                        <option value = "16:40">16:40</option>
                                                        <option value = "16:45">16:45</option>
                                                        <option value = "16:50">16:50</option>
                                                        <option value = "16:55">16:55</option>
                                                        <option value = "17:00">17:00</option>
                                                        <option value = "17:05">17:05</option>
                                                        <option value = "17:10">17:10</option>
                                                        <option value = "17:15">17:15</option>
                                                        <option value = "17:20">17:20</option>
                                                        <option value = "17:25">17:25</option>
                                                        <option value = "17:30">17:30</option>
                                                        <option value = "17:35">17:35</option>
                                                        <option value = "17:40">17:40</option>
                                                        <option value = "17:45">17:45</option>
                                                        <option value = "17:50">17:50</option>
                                                        <option value = "17:55">17:55</option>
                                                        <option value = "18:00">18:00</option>
                                                        <option value = "18:05">18:05</option>
                                                        <option value = "18:10">18:10</option>
                                                        <option value = "18:15">18:15</option>
                                                        <option value = "18:20">18:20</option>
                                                        <option value = "18:25">18:25</option>
                                                        <option value = "18:30">18:30</option>
                                                        <option value = "18:35">18:35</option>
                                                        <option value = "18:40">18:40</option>
                                                        <option value = "18:45">18:45</option>
                                                        <option value = "18:50">18:50</option>
                                                        <option value = "18:55">18:55</option>
                                                        <option value = "19:00">19:00</option>
                                                        <option value = "19:05">19:05</option>
                                                        <option value = "19:10">19:10</option>
                                                        <option value = "19:15">19:15</option>
                                                        <option value = "19:20">19:20</option>
                                                        <option value = "19:25">19:25</option>
                                                        <option value = "19:30">19:30</option>
                                                        <option value = "19:35">19:35</option>
                                                        <option value = "19:40">19:40</option>
                                                        <option value = "19:45">19:45</option>
                                                        <option value = "19:50">19:50</option>
                                                        <option value = "19:55">19:55</option>
                                                        <option value = "20:00">20:00</option>
                                                        <option value = "20:05">20:05</option>
                                                        <option value = "20:10">20:10</option>
                                                        <option value = "20:15">20:15</option>
                                                        <option value = "20:20">20:20</option>
                                                        <option value = "20:25">20:25</option>
                                                        <option value = "20:30">20:30</option>
                                                        <option value = "20:35">20:35</option>
                                                        <option value = "20:40">20:40</option>
                                                        <option value = "20:45">20:45</option>
                                                        <option value = "20:50">20:50</option>
                                                        <option value = "20:55">20:55</option>
                                                        <option value = "21:00">21:00</option>
                                                        <option value = "21:05">21:05</option>
                                                        <option value = "21:10">21:10</option>
                                                        <option value = "21:15">21:15</option>
                                                        <option value = "21:20">21:20</option>
                                                        <option value = "21:25">21:25</option>
                                                        <option value = "21:30">21:30</option>
                                                        <option value = "21:35">21:35</option>
                                                        <option value = "21:40">21:40</option>
                                                        <option value = "21:45">21:45</option>
                                                        <option value = "21:50">21:50</option>
                                                        <option value = "21:55">21:55</option>
                                                        <option value = "22:00">22:00</option>
                                                        <option value = "22:05">22:05</option>
                                                        <option value = "22:10">22:10</option>
                                                        <option value = "22:15">22:15</option>
                                                        <option value = "22:20">22:20</option>
                                                        <option value = "22:25">22:25</option>
                                                        <option value = "22:30">22:30</option>
                                                        <option value = "22:35">22:35</option>
                                                        <option value = "22:40">22:40</option>
                                                        <option value = "22:45">22:45</option>
                                                        <option value = "22:50">22:50</option>
                                                        <option value = "22:55">22:55</option>
                                                        <option value = "23:00">23:00</option>
                                                        <option value = "23:05">23:05</option>
                                                        <option value = "23:10">23:10</option>
                                                        <option value = "23:15">23:15</option>
                                                        <option value = "23:20">23:20</option>
                                                        <option value = "23:25">23:25</option>
                                                        <option value = "23:30">23:30</option>
                                                        <option value = "23:35">23:35</option>
                                                        <option value = "23:40">23:40</option>
                                                        <option value = "23:45">23:45</option>
                                                        <option value = "23:50">23:50</option>
                                                        <option value = "23:55">23:55</option>
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                              {/* <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Case Type<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.caseType} id = "caseType" name = "caseType" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Case Type</option>
                                                        <option value = "AGRILOAN RV">AGRILOAN RV</option>
                                                        <option value = "AGRILOAN BV">AGRILOAN BV</option>
                                                        <option value = "CREDIT CARD RV">CREDIT CARD RV</option>
                                                        <option value = "CREDIT CARD BV">CREDIT CARD BV</option>
                                                        <option value = "PERSONAL LOAN RV">PERSONAL LOAN RV</option>
                                                        <option value = "PERSONAL LOAN BV">PERSONAL LOAN BV</option>
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row> */}

                                              <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Remarks</Label>
                                                        <Input
                                                          name="remarks"
                                                          placeholder="Remarks"
                                                          type="text"
                                                          className="form-control"
                                                          id="remarks"
                                                          value = {this.state.remarks}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                {this.state.stateValue.includes("SUPER ADMIN") ?

                                                <Row>
                                                   <Col md = {{size: 6}}>
                                                       <FormGroup>
                                                           <Label htmlFor="validationCustom05">State<span style = {{color: "red"}}>*</span></Label>
                                                           <Select
                                                                value = {this.state.stateName}
                                                                onChange={this.handleState}
                                                                options={this.state.states}
                                                                id= "stateName"
                                                                placeholder = "Select State"
                                                                isMulti = {true}
                                                                styles = {customStyles1}
                                                            />
                                                           {/* <Input type="select"  value = {this.state.pvAgent} id = "pvAgent" name = "pvAgent" onChange = {this.agentsOnChangePV.bind(this)}>
                                                           <option value = "">Select Agent</option>
                                                             {this.state.agents.map((rowData, index) => (
                                                               <option value = {rowData.UserId}>{rowData.Name}</option>
                                                             ))}
                                                         </Input> */}
                                                       </FormGroup>
                                                   </Col>
                                                   </Row> : null }

                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                {/* <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Bank Successfulsly Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                  <SweetAlert
                                                    title="Great"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ update_msg: false })}
                                                  >
                                                    Bank Updated Successfully!
                                                  </SweetAlert>
                                                ) : null}      */}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                                          <Col>
                                          <Card>
                                            <CardBody>
                                              

                                          {/* <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Reference Number</th>
                                                            <th>Applicant Name</th>
                                                            <th>Bank</th>
                                                            <th>Product</th>
                                                            <th>TAT Time</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.ApplicationId}</td>
                                                        <td>{rowData.CustomerName}</td>
                                                        <td>{rowData.Bank}</td>
                                                        <td>{rowData.ProductName}</td>
                                                        <td>{rowData.TATTime}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default CreateCase;