import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase';
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class DeleteOldCase extends Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.funcMap = {
        '2': this.handleFilter,
        '3': this.delete,
        '98': this.handleChangePage,
        '99': this.handleChangeRowsPerPage
    };
    this.state = {
      firebaseData: [],
      selectAll: false,
      startDate: '',
      endDate: '',
      load: false,
      pages: [30, 50, 100, 300, 1000],
      page: 0,
      rowsPerPage: 30,
      lastVisible: {},
      count: 0,
      queryParams: {},
      totalRecords: 0,
      banks: [],
      bank: '',
    };
  }

  componentDidMount() {
    let comp = this
    let count = 0


    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    // firebase.firestore().collection("Case")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //             count = count + 1
    //     });
    //     comp.setState({
    //       count: count
    //     })
    // });

    // firebase.firestore().collection("Case")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .limit(this.state.rowsPerPage)
    // .get().then((querySnapshot) => {
    //     var loans = [];

    //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
    //     console.log("last", lastVisible);

    //     querySnapshot.forEach(function(doc) {
    //         loans.push(doc.data())
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //       lastVisible: lastVisible
    //     })
    // });

    // firebase.firestore().collection("Case")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //           const loan = {
    //             ApplicationId: doc.data().ApplicationId,
    //             CustomerName: doc.data().CustomerName,
    //             Bank: doc.data().Bank,
    //             Product: doc.data().ProductName,
    //             CaseType: doc.data().FI,
    //             TATTime: doc.data().TATTime,
    //             Agent: doc.data().AgentName,
    //             Status: doc.data().Status,
    //             Allocation_Date_Time: doc.data().CreatedAt,
    //           }
    //           loans.push(loan)
    //     });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });

    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        banks: response1.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }

  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  handleFilter(e) {
    e.preventDefault();

  let data = this.state
  let comp = this

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  let banks = localStorage.getItem("bank")

  if(banks === null) {
    banks = sessionStorage.getItem("bank");
  }

  var jsonData = {
    state: state.split(",").slice(0, -1),
    startDate: data.startDate,
    endDate: data.endDate,
    bank: banks
  };

  if(data.bank !== "" || !banks.includes("SUPER ADMIN")) {
    if(data.bank === 'DMI FINANCE PRIVATE LIMITED'){
      jsonData.bank = ["DMI FINANCE PRIVATE LIMITED", "DMI FINANCIAL PRIVATE LIMITED", "DMI Finance Pvt. Ltd."];
    }
    else {
      jsonData.bank = data.bank || banks.split(",").slice(0, -1);
    }
  }

  if (!data.startDate) {
    delete jsonData.startDate; // Remove startDate if not selected
  }

  if (!data.endDate) {
    delete jsonData.endDate; // Remove endDate if not selected
  }

  if(!data.bank){
    delete jsonData.bank;
  }

  comp.setState({
    queryParams: jsonData
  })

  jsonData.pagination = true
  jsonData.pageSize = comp.state.rowsPerPage
  jsonData.pageCount = Number(comp.state.page) + 1

  let jsonData1 = JSON.stringify(jsonData);

  var config = {
    method: 'post',
    url: API_BASE_URL + '/auth/report/pendingCase',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json',
    },
    data: jsonData1
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
            comp.setState({
              firebaseData: response.data.caseList,
              totalRecords: response.data.totalRecords,
              load:false
          })
      }
  })
  .catch(function (error) {
    console.log(error);
    comp.handleNon200Response(error, "2");
  });

  // firebase.firestore().collection("Case")
  // .where("State", "array-contains-any", state.split(",").slice(0, -1))
  // .get().then((querySnapshot) => {
  //     var loans = [];
  //     querySnapshot.forEach(function(doc) {
  //         if(typeof doc.data().CreatedAt == "string") {
  //           if((formatDate(doc.data().CreatedAt) >= data.startDate) && (formatDate(doc.data().CreatedAt) <= data.endDate)) {
  //               loans.push(doc.data())
  //           }
  //       }
  //     });


  //     console.log(loans)

  //     comp.setState({
  //       firebaseData: loans,
  //       load: false
  //     })
  // });
  }

  checkAll(ele) {

    const value = ele.target.checked;
  
    this.setState({
      selectAll: !this.state.selectAll
    })
  
  
    var grid1 = document.getElementById("dataTable");
    var checkBoxes = grid1.getElementsByTagName("input");
    if (value) {
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].type == 'checkbox') {
          checkBoxes[i].checked = true;
        }
      }
    } else {
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].type == 'checkbox') {
          checkBoxes[i].checked = false;
        }
      }
    }
  }

  delete(e) {
    let comp = this
    if (window.confirm('Are you sure want to delete the selected case?')) {
      var grid = document.getElementById("dataTable");
      var pushId = []

      var checkBoxes1 = grid.getElementsByTagName("input");
      for (var i = 0; i < checkBoxes1.length; i++) {
        if (checkBoxes1[i].checked) {
          var row = checkBoxes1[i].parentNode.parentNode;
          pushId.push(row.cells[1].innerHTML);
        }
      }

      if(pushId.length === 0) {
        alert("Select Cases to Delete")
        return
      }

      console.log(pushId)

      comp.setState({
        load: true
      })

      for(let i =0; i< pushId.length; i++) {

        var config = {
          method: 'delete',
          url:  API_BASE_URL + '/auth/case/' + pushId[i],
          headers: { 
            'apikey': API_KEY,
            'Authorization': 'Bearer ' + getCookie('at')
          },
        };
      
        axios(config)
        .then(function (response) {
          if(response.status == 200) {
          }
        })
        .catch(function (error) {
          console.log(error)
          comp.handleNon200Response(error, "3");
        });
      }
        alert("Successfully Deleted!")

        comp.setState({
          load: false,
          startDate: '',
          endDate: '',
          firebaseData: [],
          totalRecords: 0,
          selectAll: false
        })

      } else {}
  
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })

    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(newPage) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/pendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.caseList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "98");
    });
  }
  
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })

    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = parseInt(event.target.value, 10)
    jsonData.pageCount = Number(0) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/pendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.caseList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "99");
    });
  }

  searchByApplicationId(e) {
    let data = this.state
    let comp = this
  
    console.log("Search")
  
    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }
  
    comp.setState({
      firebaseData: []
    })
  
    if(data.applicationId === '') {
      alert("Enter Application ID")
      document.getElementById("applicationId").focus()
      return
    }
  
    let jsonData = {
      state: state.split(",").slice(0, -1),
      applicationId: data.applicationId
    }
  
    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }
  
    if(!bank.includes("SUPER ADMIN")) {
      jsonData.bank = bank.split(",").slice(0, -1)
    }
  
    let jsonData1 = JSON.stringify(jsonData);
  
    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/case/searchByApplicationId',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
              comp.setState({
                firebaseData: response.data,
                totalRecords: 1,
            })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }



  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="Delete Old Case" />
            {this.state.moveToView ? <Redirect to = "view-case" /> : null}

            {this.state.load ? 
              <Loader 
                type="Circles" 
                color="#4058b0" 
                height={130} 
                width={130}
                timeout={10000}
                style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2 }}
              /> : null }
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                        <Col md = {3} className = "input-group">
                                                <input className="form-control" placeholder = "Application ID"  type="applicationId" name="applicationId" id="applicationId" value = {this.state.applicationId}  onChange = {this.handleChange.bind(this)} />
                                                <span className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="search">
                                                    <FontAwesomeIcon style = {{fontSize: "10px", cursor: "pointer"}} icon={faSearch} onClick = {this.searchByApplicationId.bind(this)}/>
                                                    </button>
                                                </span>
                                            </Col>
                                        </Row>
                                        <br />
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            </Col>
                                        </Row>
                                        <br />


                                            <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilter.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                  

                                        <br />
                                        {this.state.firebaseData.length > 0 ?
                                        <Button color="danger" onClick = {this.delete.bind(this)} id = "submit" type="submit">Delete</Button> : null }
                                        <br /> <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th><input type = "checkbox"  onChange = {this.checkAll.bind(this)} checked = {this.state.selectAll} ></input>&nbsp; Select All</th>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Bank Name</th>
                                                            <th>Case Type</th>
                                                            <th>Status</th>
                                                            <th>Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody id = "dataTable">
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td><input type = "checkbox" ></input><span className="vs-checkbox"><span className="vs-checkbox--check"></span></span></td>
                                                        <td style = {{display: "none"}}>{rowData._id}</td>
                                                        <td style = {{display: "none"}}>{rowData.PushId}</td>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.applicationid}</td> 
                                                        <td>{rowData.customername}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.fi}</td>
                                                        <td>{rowData.status}</td>
                                                        <td>{rowData.createdat}</td>                                                        
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default DeleteOldCase;