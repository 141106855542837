import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Container, Input, Form, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import Modal from "reactstrap/lib/Modal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError, sessionExpired } from "../../constants/utilis";


class SearchCaseReport extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.getCaseDetails = this.getCaseDetails.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.funcMap = {
            '1': this.fetchList,
            '2': this.handleSubmit,
            '3': this.handleFilter,
            '5': this.getCaseDetails,
            '98': this.handleChangePage,
            '99': this.handleChangeRowsPerPage
        };
        this.state = {
            firebaseData: [],
            firebaseData1: [],
            videoName: '',
            menu: 'Select',
            success_msg: false,
            agents: [],
            agentId: [],
            isClearable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,
            isSearchable: true,
            banks: [],
            products: [],
            bank: '',
            product: '',
            moveToView: false,
            openModal: false,
            applicationId: '',
            customerName: '',
            mobileNumber: '',
            productName: '',
            rvAddress: '',
            bvAddress: '',
            pvAddress: '',

            pages: [30, 50, 100, 300, 1000],
            page: 0,
            rowsPerPage: 30,
            lastVisible: {},
            count: 0,
            queryParams: {},
            totalRecords: 0
        };
    }

    componentDidMount() {
        let comp = this

        var state = localStorage.getItem("state")
        if (state === null) {
            state = sessionStorage.getItem("state")
        }

        comp.fetchList()

        var config1 = {
            method: 'get',
            url: API_BASE_URL + '/auth/bank',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
            }
        };

        axios(config1)
            .then(function (response1) {
                comp.setState({
                    banks: response1.data
                })
            })
            .catch(function (error) {
                console.log(error);
                // comp.handleNon200Response(error, "1");
            });

        var config2 = {
            method: 'get',
            url: API_BASE_URL + '/auth/loanproduct',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
            }
        };

        axios(config2)
            .then(function (response2) {
                comp.setState({
                    products: response2.data
                })
            })
            .catch(function (error) {
                console.log(error);
                // comp.handleNon200Response(error, "1");
            });

    }

    async fetchList() {
        let data = this.state
        let comp = this

        let banks = localStorage.getItem("bank")
        if (banks === null) {
            banks = sessionStorage.getItem("bank")
        }

        var jsonData = {
            state: 'SUPER ADMIN',
            bank: banks,
        };
        if(banks !== "" || !banks.includes("SUPER ADMIN")) {
            if(banks == 'DMI FINANCE PRIVATE LIMITED' || banks == "DMI FINANCE PRIVATE LIMITED,"){
              jsonData.bank = ["DMI FINANCE PRIVATE LIMITED", "DMI FINANCIAL PRIVATE LIMITED", "DMI Finance Pvt. Ltd."];
            }
            else {
              jsonData.bank = data.bank || banks.split(",").slice(0, -1);
            }
        }

        comp.setState({
            queryParams: jsonData
        })

        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/report/case',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log('fecth list', response.data)
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data.caseList,
                        totalRecords: response.data.totalRecords
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error, "1");
            });
    }

    handleChangePage(event, newPage) {
        this.setState({
            page: newPage
        })

        let comp = this
        let jsonData = comp.state.queryParams

        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(newPage) + 1

        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/report/case',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data.caseList,
                        totalRecords: response.data.totalRecords
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error, "98");
            });
    }

    handleNon200Response = (error, functionIndex) => {
        if (error.response.status == 401 && error.response.data.message == "User Not authenticated") {
            sessionExpired();
        }
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })

        let comp = this
        let jsonData = comp.state.queryParams

        jsonData.pagination = true
        jsonData.pageSize = parseInt(event.target.value, 10)
        jsonData.pageCount = Number(0) + 1

        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/report/case',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data.caseList,
                        totalRecords: response.data.totalRecords
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error, "99");
            });
    }

    agentsOnChange = agentId => {
        this.setState({ agentId });
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = this.state

        let comp = this

        let bank = localStorage.getItem("bank")
        if (bank === null) {
            bank = sessionStorage.getItem("bank")
        }

        let jsonData = {
            state: "SUPER ADMIN",
            bank: bank.split(",").slice(0, -1),
        }

        if (data.product !== "") {
            jsonData.product = data.product
        }

        comp.setState({
            queryParams: jsonData
        })

        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        var jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/report/case',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log("handle submit", response.data)
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data.caseList,
                        totalRecords: response.data.totalRecords
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error, "2");
            });
    }

    handleFilter(e) {
        e.preventDefault();

        let data = this.state
        let comp = this

        // let bank = ["DMI FINANCE PRIVATE LIMITED", "DMI FINANCIAL PRIVATE LIMITED"]

        if (data.startDate === '') {
            alert("Select Start date");
            document.getElementById("startDate").focus();
            return;
        }

        if (data.endDate === '') {
            alert("Select End Date");
            document.getElementById("endDate").focus();
            return;
        }

        let bank = localStorage.getItem("bank")

        if (bank === null) {
            bank = sessionStorage.getItem("bank");
        }

        var jsonData = {
            state: "SUPER ADMIN",
            startDate: data.startDate,
            endDate: data.endDate,
            bank: bank,
        };

        if(bank !== "" || !bank.includes("SUPER ADMIN")) {
            if(bank == 'DMI FINANCE PRIVATE LIMITED'  || bank == "DMI FINANCE PRIVATE LIMITED,"){
              jsonData.bank = ["DMI FINANCE PRIVATE LIMITED", "DMI FINANCIAL PRIVATE LIMITED", "DMI Finance Pvt. Ltd."];
            }
            else {
              jsonData.bank = data.bank || bank.split(",").slice(0, -1);
            }
        }



        //   if(!bank.includes("SUPER ADMIN")) {
        //     jsonData.bank = bank.split(",").slice(0, -1);
        //   }

        comp.setState({
            queryParams: jsonData
        })

        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/report/case',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json',
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log("handle filter", response.data)
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data.caseList,
                        totalRecords: response.data.totalRecords
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error, "3");
            });
    }

    viewInfo(key) {
        let comp = this

        sessionStorage.setItem("caseId", key._id)
        sessionStorage.setItem("fromComp", "case")

        window.open("/view-case-client")

        // comp.setState({
        //   moveToView: true
        // })
    }

    toggle() {
        let comp = this

        comp.setState({
            openModal: false
        })
    }

    getCaseDetails(key) {
        let comp = this

        console.log(key)

        let rvAddress = []
        let bvAddress = []
        let pvAddress = []

        comp.setState({
            rvAddress: '',
            bvAddress: '',
            pvAddress: ''
        })

        var state = localStorage.getItem("state")
        if (state === null) {
            state = sessionStorage.getItem("state")
        }

        var jsonData = JSON.stringify({
            state: "SUPER ADMIN",
            applicationId: key.applicationid,
        })


        var config = {
            method: "post",
            url: API_BASE_URL + "/auth/case/searchByApplicationId",
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                "Content-Type": "application/json",
            },
            data: jsonData,
        }

        axios(config)
            .then(function (response) {
                if (response.status == 200) {
                    for (let i = 0; i < response.data.length; i++) {
                        rvAddress.push(response.data[i].residenceaddress)
                        bvAddress.push(response.data[i].officeaddress)
                        pvAddress.push(response.data[i].permanentaddress)
                    }

                    for (let i = 0; i < rvAddress.length; i++) {
                        if (rvAddress[i] !== undefined) {
                            comp.setState({
                                rvAddress: rvAddress[i]
                            })
                        }
                    }

                    for (let i = 0; i < bvAddress.length; i++) {
                        if (bvAddress[i] !== undefined) {
                            comp.setState({
                                bvAddress: bvAddress[i]
                            })
                        }
                    }
                    for (let i = 0; i < pvAddress.length; i++) {
                        if (pvAddress[i] !== undefined) {
                            comp.setState({
                                pvAddress: pvAddress[i]
                            })
                        }
                    }

                    comp.setState({
                        applicationId: key.applicationid,
                        customerName: key.customername,
                        mobileNumber: key.mobilenumber,
                        productName: key.productname,
                        openModal: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error.response.data)
                comp.handleNon200Response(error, "5")
            })
    }

    exportToCSV(fileName) {
        let comp = this

        // Get the filtered data or fallback to the full data
        let dataToExport = comp.state.firebaseData;
    
        if (!dataToExport || dataToExport.length === 0) {
          // Fetch the full data here if needed, otherwise fallback to an empty array.
          dataToExport = []; // or fetch the original dataset here
        }
      
        let loans = [];
      
        // Process the data for export
        if (dataToExport.length > 0) {
          for (let i = 0; i < dataToExport.length; i++) {
            const loan = {
              SL_No : i+1,
              ApplicationId: dataToExport[i].applicationid,
              CustomerName: dataToExport[i].customername,
              Bank: dataToExport[i].bank,
              Product: dataToExport[i].productname,
              CaseType: dataToExport[i].fi,
              TATTime: dataToExport[i].tattime,
              AgentName: dataToExport[i].agentname,
              Status: dataToExport[i].status,
              Allocation_Date_Time: (dataToExport[i].createdat == undefined || dataToExport[i].createdat == "") ? "" : dataToExport[i].createdat.slice(0, 10),
              Completion_Date_Time: (dataToExport[i].submittedat == undefined || dataToExport[i].submittedat == "") ? "" : dataToExport[i].submittedat.slice(0, 10),
              CPV_Status: (dataToExport[i].qcpvstatus == undefined) ? "" : dataToExport[i].qcpvstatus,
              Reject_Reason: (dataToExport[i].qrejectreason == undefined) ? "" : dataToExport[i].qrejectreason,
            //   Address :(dataToExport[i].residenceaddress) ? dataToExport[i].residenceaddress : (dataToExport[i].officeaddress) ? dataToExport[i].officeaddress : "",
            //   Contact_Number: dataToExport[i].mobilenumber,
            }
            loans.push(loan);
          }
        }
    
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(loans);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    searchByApplicationId() {
        let data = this.state;
        let comp = this;

        // var state = localStorage.getItem("state") || sessionStorage.getItem("state");
        let bank = localStorage.getItem("bank") || sessionStorage.getItem("bank");

        if (data.applicationId === '') {
            alert("Enter Application ID");
            document.getElementById("applicationId").focus();
            return;
        }

        let jsonData = {
            state: "SUPER ADMIN",
            applicationId: data.applicationId,
            bank: bank.split(",").slice(0, -1),
        };

        // if(!bank.includes("SUPER ADMIN")) {
        //   jsonData.bank = bank.split(",").slice(0, -1);
        // }

        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/case/searchByApplicationId',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                if (response.data.length < 1) {
                    alert("No records found");
                }
                if (response.status == 200) {
                    comp.setState({
                        firebaseData: response.data,
                        totalRecords: response.data.length,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                comp.handleNon200Response(error);
            });
    }

    render() {
        let bank = "SUPER ADMIN"

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Report" breadcrumbItem="Search Case Report" />
                        {this.state.moveToView ? <Redirect to="view-case" /> : null}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id="user-list">
                                        <Row>
                                            <Col md={3} className="input-group">
                                                <input className="form-control" placeholder="Application ID" type="applicationId" name="applicationId" id="applicationId" value={this.state.applicationId} onChange={this.handleChange.bind(this)} />
                                                <span className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="search">
                                                        <FontAwesomeIcon style={{ fontSize: "10px", cursor: "pointer" }} icon={faSearch} onClick={this.searchByApplicationId.bind(this)} />
                                                    </button>
                                                </span>
                                            </Col>

                                            <Col md="6"></Col>
                                            <Col md="2">
                                                <Button color="primary" onClick={this.exportToCSV.bind(this, "Case Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />

                                        <Row>
                                            <Col md={{ size: 3 }}>
                                                <FormGroup>
                                                    <Label htmlFor="validationCustom05">Products<span style={{ color: "red" }}>*</span></Label>
                                                    <Input type="select" value={this.state.product} id="product" name="product" onChange={this.handleChange.bind(this)}>
                                                        <option value="">Select Product</option>
                                                        {this.state.products.map((rowData, index) => (
                                                            <option value={rowData.name}>{rowData.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={{ size: 3 }} style={{ margin: "auto" }}>
                                                <Button color="primary" onClick={this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                            </Col>
                                        </Row>

                                        <br />

                                        <Row>
                                            <Col md={{ size: 3 }}>
                                                <FormGroup>
                                                    <Label htmlFor="validationCustom05">Start Date<span style={{ color: "red" }}>*</span></Label>
                                                    <Input type="date" value={this.state.startDate} id="startDate" name="startDate" onChange={this.handleChange.bind(this)}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={{ size: 3 }}>
                                                <FormGroup>
                                                    <Label htmlFor="validationCustom05">End Date<span style={{ color: "red" }}>*</span></Label>
                                                    <Input type="date" value={this.state.endDate} id="endDate" name="endDate" onChange={this.handleChange.bind(this)}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={{ size: 3 }} style={{ margin: "auto" }}>
                                                <Button color="primary"
                                                    onClick={this.handleFilter.bind(this)}
                                                    type="submit">Filter</Button>
                                            </Col>
                                        </Row>

                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id="dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>Application Id</th>
                                                        <th>Customer Name</th>
                                                        <th>Bank</th>
                                                        <th>Product</th>
                                                        <th>Case Type</th>
                                                        <th>TAT Time</th>
                                                        <th>Agent</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.firebaseData.map((rowData, index) => (
                                                        <>
                                                            {bank.includes("SUPER ADMIN") || rowData.showToClient == "YES" ?
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td onClick={this.getCaseDetails.bind(this, rowData)} style={{ color: "#556EE6", cursor: "pointer" }}>{rowData.applicationid}</td>
                                                                    <td>{rowData.customername}</td>
                                                                    <td>{rowData.bank}</td>
                                                                    <td>{rowData.productname}</td>
                                                                    <td>{rowData.fi}</td>
                                                                    <td>{rowData.tattime}</td>
                                                                    <td>{rowData.agentname}</td>
                                                                    <td style={{
                                                                        backgroundColor: rowData.status === "Completed" ? "green" : "inherit",
                                                                        color: rowData.status === "Completed" ? "white" : "inherit",
                                                                        padding: "5px 10px",
                                                                        borderRadius: "5px",
                                                                        display: "inline-block",
                                                                        cursor: "not-allowed",
                                                                    }}>{rowData.status} <br />
                                                                        {rowData.status === "Completed" ?
                                                                            <Row>
                                                                                <Col><FontAwesomeIcon style={{ cursor: "pointer" }} onClick={this.viewInfo.bind(this, rowData)} icon={faInfo} /></Col>
                                                                            </Row> : null}
                                                                    </td>
                                                                </tr>
                                                                : null}
                                                        </>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                            component="div"
                                            page={this.state.page}
                                            rowsPerPageOptions={this.state.pages}
                                            rowsPerPage={this.state.rowsPerPage}
                                            count={this.state.totalRecords}
                                            onChangePage={this.handleChangePage.bind(this)}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                        />

                                        <Modal isOpen={this.state.openModal} toggle={this.toggle.bind(this)}>
                                            <ModalHeader toggle={this.toggle.bind(this)}>{this.state.applicationId}</ModalHeader>
                                            <ModalBody>
                                                <Row>
                                                    <Col md={4}>
                                                        <h6>Customer Name</h6>
                                                    </Col>
                                                    <Col md={8}>
                                                        <h6>: {this.state.customerName}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <h6>Mobile Number</h6>
                                                    </Col>
                                                    <Col md={8}>
                                                        <h6>: {this.state.mobileNumber}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <h6>Product</h6>
                                                    </Col>
                                                    <Col md={8}>
                                                        <h6>: {this.state.productName}</h6>
                                                    </Col>
                                                </Row>
                                                {this.state.pvAddress !== '' ?
                                                    <Row>
                                                        <Col md={4}>
                                                            <h6>PV Address</h6>
                                                        </Col>
                                                        <Col md={8}>
                                                            <h6>: {this.state.pvAddress}</h6>
                                                        </Col>
                                                    </Row> : null}
                                                {this.state.rvAddress !== '' ?
                                                    <Row>
                                                        <Col md={4}>
                                                            <h6>RV Address</h6>
                                                        </Col>
                                                        <Col md={8}>
                                                            <h6>: {this.state.rvAddress}</h6>
                                                        </Col>
                                                    </Row> : null}
                                                {this.state.bvAddress !== '' ?
                                                    <Row>
                                                        <Col md={4}>
                                                            <h6>BV Address</h6>
                                                        </Col>
                                                        <Col md={8}>
                                                            <h6>: {this.state.bvAddress}</h6>
                                                        </Col>
                                                    </Row> : null}
                                            </ModalBody>
                                        </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SearchCaseReport;