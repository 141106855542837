import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, Input } from "reactstrap";
import Button from "reactstrap/lib/Button";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import { TablePagination } from '@material-ui/core';
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
import axios from "axios";

class AgentApprovals extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.approveAgents = this.approveAgents.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.funcMap = {
            '1': this.fetchList,
            '2': this.approveAgents,
            '98': this.handleChangePage,
            '99': this.handleChangeRowsPerPage
        };
        this.state = {
            firebaseData: [],
            profile: false,
            agentId: '',
            changedRow: '0',
            agents: [],
            pages: [30, 50, 100, 300, 1000],
            page: 0,
            rowsPerPage: 30,
            queryParams: {},
            totalRecords: 0
        };
    }

    componentDidMount() {
        let agents = []
        let comp = this
        var config = {
            method: 'get',
            url: API_BASE_URL + '/auth/agents',
            headers: { 
              apikey: API_KEY, 
              Authorization: "Bearer " + getCookie("at"),
            }
          };
          
          axios(config)
          .then(function (response) {
              console.log(response.data)
              for(let i=0; i< response.data.length; i++) {
                if(response.data[i].agentid != "") {
                  agents.push(response.data[i].agentid)
                }
              }
              comp.setState({
                agents: agents
            })
          })
          .catch(function (error) {
            console.log(error);
            comp.handleNon200Response(error, "1");
          });
        this.fetchList();
      }
    
      async fetchList() {
        let comp = this

        let jsonData = {}

        comp.setState({
          queryParams: jsonData
        })
    
        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/pendingAgents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
          console.log(response.data)
          comp.setState({
            firebaseData: response.data.agentsList,
            totalRecords: response.data.totalRecords
          })
        })
        .catch(function (error) {
          console.log(error);
          // comp.handleNon200Response(error, "1");
        });
      }
    
      handleNon200Response = (error, functionIndex) => {
        if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
          sessionExpired();
        }
      }

      handleChangePage(event, newPage) {
        this.setState({
          page: newPage
        })

        let comp = this
        let jsonData = comp.state.queryParams
    
        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(newPage) + 1
    
        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/pendingAgents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
          console.log("Response", response.data)
            if(response.status == 200) {
              comp.setState({
                firebaseData: response.data.agentsList,
                totalRecords: response.data.totalRecords
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "98");
        });
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({
          rowsPerPage: parseInt(event.target.value, 10),
          page: 0
        })

        let comp = this
        let jsonData = comp.state.queryParams
    
        jsonData.pagination = true
        jsonData.pageSize = parseInt(event.target.value, 10)
        jsonData.pageCount = Number(0) + 1
    
        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/pendingAgents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
            if(response.status == 200) {
              comp.setState({
                firebaseData: response.data.agentsList,
                totalRecords: response.data.totalRecords
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "99");
        });
      }
    // componentDidMount() {
    //     let comp = this
    //     firebase.firestore().collection("Agents")
    //     .where("ApprovalStatus", "==", "Pending")
    //     .onSnapshot(function(querySnapshot) {
    //         var ags = [];
    //         querySnapshot.forEach(function(doc) {
    //             ags.push(doc.data())
    //         });
    //         comp.setState({
    //           firebaseData: ags,
    //         })
    //     });

    //     firebase.firestore().collection("Agents")
    //     .onSnapshot(function(querySnapshot) {
    //         var agents = [];
    //         querySnapshot.forEach(function(doc) {
    //             if(doc.data().AgentId !== undefined) {
    //                 agents.push(doc.data().AgentId)
    //             }
    //         });
    //         comp.setState({
    //           agents: agents,
    //         })
    //     });
    // }

    approveAgents(key, index) {
        let comp = this
        if(index !== this.state.changedRow && this.state.agentId === '') {
            alert("Enter Agent Id")
            return
        }

        console.log("AgentIds", this.state.agents)

        if(this.state.agents.indexOf(this.state.agentId) !== -1) {
            alert("Agent Id already exists")
            return
        }

        if (window.confirm('Are you sure you want to approve the agent?')) {
            var jsonData = JSON.stringify({
                "approvalstatus": "Approved",
                "agentid": this.state.agentId,
              })
          
              var config = {
                method: "put",
                url: API_BASE_URL + "/auth/agents/" + key._id,
                headers: {
                  apikey: API_KEY,
                  Authorization: "Bearer " + getCookie("at"),
                  "Content-Type": "application/json",
                },
                data: jsonData,
              }
          
              axios(config)
                .then(function (response) {
                  if (response.status == 200) {
                    comp.fetchList()
                    alert("Approved Successfully!!")
                  }
                })
                .catch(function (error) {
                  console.log(error)
                  comp.handleNon200Response(error, "2")
                })
            // firebase.firestore().collection("Agents").doc(key.UserId).update({
            //     ApprovalStatus: "Approved",
            //     AgentId: this.state.agentId
            // })
            // .then(function(ref) {
            //     alert("Approved Successfully!!")
            // })
        } else {}
    }

    handleChange(event, index) {
        this.setState({
            [event.target.name]: event.target.value,
            changedRow: index
        });
      }

      myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        td4 = tr[i].getElementsByTagName("td")[4];
        td5 = tr[i].getElementsByTagName("td")[5];
        td6 = tr[i].getElementsByTagName("td")[6];
        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4.textContent || td4.innerText;
          txtValue5 = td5.textContent || td5.innerText;
          txtValue6 = td6.textContent || td6.innerText;
        
        var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6;
          if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
      }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Approvals" breadcrumbItem="Agent Approvals" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <Col md = "4">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            {/* <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData, "User Report")} type="submit">Excel</Button> */}
                                            {/* <ReactHTMLTableToExcel  
                                                className="btn btn-primary"  
                                                table="dataTableExcel"  
                                                filename="Delivery Agent Report"  
                                                sheet="Sheet"  
                                                buttonText="Excel" />   */}
                                            </Col>
                                        </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>User Id</th>
                                                            <th>Name</th>
                                                            <th>Number</th>
                                                            <th>Email</th>
                                                            <th>ID Proof</th>
                                                            <th>Address Proof</th>
                                                            <th>Agent Id</th>
                                                            <th>Approve</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.userid}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.number}</td>
                                                        <td>{rowData.email}</td>
                                                        <td>{rowData.idPpoof}</td>
                                                        <td><a href = {rowData.adressproofimage} target = "_blank">{rowData.adressproof}</a></td>
                                                        <td><Input
                                                          name="agentId"
                                                          placeholder="Agent Id"
                                                          type="text"
                                                          className="form-control"
                                                          id="agentId"
                                                          onChange={(event) => this.handleChange(event, index+ 1)}
                                                        /></td>
                                                        <td><Button onClick = {this.approveAgents.bind(this, rowData, index+ 1)} color = "success">Approve</Button></td>
                                                        </tr>
                                                    ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentApprovals;
